import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { BarsLoader } from 'components/animation/loader/bars-loader';
import { Branches } from 'components/branches/branches';
import { StatementsDateSelector } from 'components/statements/statements-date-selector';
import { useAnalytics } from 'config/segment';
import { useThemeSelection } from 'hooks/useThemeSelection';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getStatementsForBranch } from 'services/merchant/statements';
import { Branch, Statement } from 'types/types';
import { getDropdownOptionsFromBranches } from 'utils/branches';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';
import { getBranchesByIds } from 'utils/entities';

export const StatementsContainer = () => {
  const { entityId, entityType } = useParams();
  const [branchId, setBranchId] = useState<string>();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState<Statement | undefined>();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const [loaded, setLoaded] = useState(false);
  const [branches, setBranches] = useState<Branch[]>([]);
  const { merchants, branchGroups, branches: branchesData } = useAppStore((state) => state.entities);
  const [loadingForDownload, setLoadingForDownload] = useState('');
  const themeSelection = useAppStore((state) => state.selectedTheme);
  const theme = useThemeSelection(themeSelection);

  if (!entityType && !entityId) {
    throw new Error('StatementsContainer - No entities found');
  }

  if (entityType !== MERCHANTS && entityType !== BRANCH_GROUPS) {
    throw new Error('StatementsContainer - Invalid entity type');
  }

  const {
    isLoading,
    isError,
    data: statements,
  } = useQuery(['statements', branchId], () => {
    if (branchId)
      return getStatementsForBranch(+branchId).then((statements) => {
        if (statements && statements.length > 0) {
          setSelected(statements[0]);
        }
        return statements;
      });
  });

  useEffect(() => {
    let branches: Branch[] = [];
    if (entityType && entityId && branchesData) {
      if (entityType === MERCHANTS && merchants) {
        branches = getBranchesByIds(merchants[entityId].branchIds, branchesData);
      } else if (entityType === BRANCH_GROUPS && branchGroups) {
        branches = getBranchesByIds(branchGroups[entityId].branchIds, branchesData);
      }
      setBranchId(branches[0].branchId);
    }
    setBranches(branches);
  }, [entityType, entityId, branchesData, merchants, branchGroups]);

  useEffect(() => {
    analytics.track('Viewed Statements Overview', {
      entity_id: entityId,
      entity_type: entityType,
      branch_id: branchId,
      path: pathname,
    });
  }, [analytics, branchId, entityId, entityType, pathname]);

  const selectStatement = (statement: Statement) => {
    if (statement.link !== selected?.link) setLoaded(false);
    setSelected(statement);

    analytics.track('Statement Period Clicked', {
      entity_id: entityId,
      entity_type: entityType,
      branch_id: branchId,
      path: pathname,
      start_date: statement.startDate,
      end_date: statement.endDate,
    });
  };

  const retryStatements = () => {
    if (branchId) {
      queryClient.resetQueries({ queryKey: ['statements'] });

      analytics.track('Statement Retry Clicked', {
        entity_id: entityId,
        entity_type: entityType,
        branch_id: branchId,
        path: pathname,
      });
    }
  };

  const onStatementActionButtonClicked = (buttonIdentifier: string, statement: Statement) => {
    analytics.track('Statement Button Clicked', {
      entity_id: entityId,
      entity_type: entityType,
      branch_id: branchId,
      button_id: buttonIdentifier,
      path: pathname,
      start_date: statement.startDate,
      end_date: statement.endDate,
    });
  };

  const printIframe = (url: string) => {
    const proxyIframe: HTMLIFrameElement = document.getElementById('proxyIframe') as HTMLIFrameElement;
    if (proxyIframe && proxyIframe.contentWindow) {
      const contentWindow = proxyIframe.contentWindow;
      if (contentWindow) {
        contentWindow.document.open();
        contentWindow.document.write(
          '<iframe src="' +
            url +
            '" onload="print();" width="100%" height="1000%" frameborder="0" marginheight="0" marginwidth="0" style="top:0!important; position:absolute!important;">',
        );
        contentWindow.document.close();
        setTimeout(() => setLoadingForDownload(''), 7000);
      }
    }
  };

  const onStatementDownloadClicked = async (buttonIdentifier: string, statement: Statement) => {
    setLoadingForDownload(statement.link);
    printIframe(statement.link);
    analytics.track('Statement Download Clicked', {
      entity_id: entityId,
      entity_type: entityType,
      branch_id: branchId,
      button_id: buttonIdentifier,
      path: pathname,
      start_date: statement.startDate,
      end_date: statement.endDate,
    });
  };

  return (
    <>
      <div className="mt-6 flex flex-col" data-testid="statement-container">
        {branches && branches.length > 0 && branchId && (
          <>
            <div className="flex flex-row mb-8">
              <div className="inline">
                <Branches
                  selectedBranch={branchId}
                  dropdownOptions={getDropdownOptionsFromBranches(branches, entityType)}
                  handleSelectedBranchChanged={(event) => {
                    setBranchId(`${event.target.value}`);
                  }}
                  enableAllBranchesOption={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {isLoading && <BarsLoader />}
      {!isLoading && statements && (
        <section className="flex relative mb-4 h-full border border-[color:var(--gray6)]">
          <StatementsDateSelector
            statements={statements}
            selectedStatement={selected}
            loading={isLoading}
            isError={isError}
            handleStatementSelect={selectStatement}
            onStatementActionButtonClicked={onStatementActionButtonClicked}
            retryStatements={retryStatements}
            onStatementDownloadClicked={onStatementDownloadClicked}
            loadingForDownloading={loadingForDownload}
          />
          {statements && statements.length > 0 && (
            <>
              <div className=" w-full overflow-scroll">
                <iframe
                  className={`w-full h-full  min-w-150 overflow-hidden overflow-y-auto ${
                    loaded ? 'transition-opacity ease-in duration-400 opacity-100' : 'opacity-0'
                  }`}
                  title="weekly report"
                  data-testid="statement-report-iframe"
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  src={`${selected?.link}${theme.darkMode?'&darkMode=true':''}`}
                ></iframe>
              </div>
            </>
          )}
        </section>
      )}
      <iframe
        id="proxyIframe"
        title="Download Statement"
        style={{ width: '100%', height: '100%', display: 'none' }}
      ></iframe>
    </>
  );
};
