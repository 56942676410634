import React from 'react';

type Props = {
  tabs: string[];
  active?: string;
  contentContainerRef?: React.RefObject<HTMLElement>;
  onTabClick?: (tab: string) => void;
  enableScroll?: boolean;
};

export const TabGroup: React.FC<Props> = ({ tabs, active, onTabClick, contentContainerRef, enableScroll = true }) => {
  const scrollSection = (section: string) => {
    const element = document.getElementById(section);
    if (contentContainerRef && element) {
      const y = element.getBoundingClientRect().top - 100;
      contentContainerRef.current?.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="flex items-center -ml-2">
      {tabs.map((tab) => (
        <div
          className={`text-base relative px-2 pt-[10px] pb-4 cursor-pointer before:border-b-2 outline-none leading-tight before:content-['']  before:left-[20%] before:-bottom-1 before:absolute before:w-[60%] before:h-1 ${
            active === tab
              ? 'before:border-[color:var(--gray12)] text-[color:var(--gray12)]'
              : 'before:border-transparent text-[color:var(--gray11)] hover:before:border-[color:var(--gray12)]'
          }`}
          key={tab}
          onClick={() => {
            if (enableScroll) scrollSection(tab);
            onTabClick?.(tab);
          }}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
