import Spinner from 'components/spinner/spinner';
import moment from 'moment';
import { ArrowUpRight, DownloadSimple } from 'phosphor-react';
import { Statement } from 'types/types';

type Props = {
  statement: Statement;
  onStatementActionButtonClicked: (identifier: string, statement: Statement) => void;
  isSelected?: boolean;
  onStatementDownloadClicked: (identifier: string, statement: Statement) => void;
  loadingForDownloading?: string;
};

export const StatementsItem = ({
  statement,
  onStatementActionButtonClicked,
  isSelected = false,
  onStatementDownloadClicked,
  loadingForDownloading,
}: Props) => {
  return (
    <div className={`flex space-x-3 items-center`}>
      <div className="flex-1 space-y-1 mt-2">
        <div className="flex items-center justify-between">
          <h3 className={`text-sm text-[color:var(--gray12)] ${isSelected ? 'font-bold' : ''}`}>
            {moment(statement.startDate).format('DD MMM yyyy')}-{moment(statement.endDate).format('DD MMM yyyy')}
          </h3>
        </div>
      </div>
      <span
        className="cursor-pointer inline-block"
        title="Open in a new tab"
        onClick={(e) => {
          e.stopPropagation();
          onStatementActionButtonClicked('Open in New Window', statement);
          window.open(statement.link);
        }}
      >
        <ArrowUpRight size={20} color="var(--gray12)" />
      </span>
      <span
        className="cursor-pointer inline-block"
        title="Download statement"
        onClick={(e) => {
          e.stopPropagation();
          onStatementDownloadClicked('Download Statement', statement);
        }}
      >
        {loadingForDownloading === statement.link && <Spinner style={{ width: '20px', height: '20px' }} />}
        {loadingForDownloading !== statement.link && <DownloadSimple size={20} color="var(--gray12)" />}
      </span>
    </div>
  );
};
