import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import { ImpressionsAggregateTableBarChart } from 'components/charts/ImpressionsAggregateTableBarChart';
import { ComposedChartContainer } from 'components/charts/composed-chart';
import { Dropdown } from 'components/lib/dropdown';
import { transformAggregateAndPeriodBrandollarData } from 'lib/data-transformers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { getAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { getBrandDollars } from 'services/merchant/brandollars/brandollars-service';
import { BrandollarMetrics, EntityType, IChartDataRequestParams, IVoucher, TimeInterval } from 'types/types';
import {
  BRANCH_GROUPS,
  BRANDOLLARS_METRICS_VALUES,
  BRANDOLLAR_BREAKDOWN_HEADER,
  BRANDOLLAR_DEFAULT_METRIC,
  MERCHANTS,
} from 'utils/constants';
import { isValidEntityType } from 'utils/entities';
import { BRANCH_GROUP_BRAND_DOLLARS_SET, MERCHANT_BRAND_DOLLARS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import {
  formatChartSubmissionDate,
  getBaseAnalyticsRequestParams,
  getBranchGroupMeticName,
  getStartAndEndDate,
} from './analytics-utils';

export const BrandollarsBreakdown = ({
  period,
  interval,
  range,
}: {
  period: string;
  interval: TimeInterval;
  range?: DateRange | null;
}) => {
  const { entityId, entityType } = useParams();
  const [requestParams, setRequestParams] = useState<IChartDataRequestParams | null>(null);
  const [metric, setMetric] = useState<BrandollarMetrics>(BRANDOLLAR_DEFAULT_METRIC);
  const { startDate, endDate } = getStartAndEndDate(period, range);
  const queryClient = useQueryClient();
  if (!entityId) {
    throw new Error('BrandollarsBreakdown - entityId is undefined');
  }

  if (!entityType || !isValidEntityType(entityType)) {
    throw new Error('BrandollarsBreakdown - entityType is undefined');
  }

  const { data: brandollars } = useQuery(['brandollars', entityType, entityId], () =>
    getBrandDollars(entityId, entityType).then((data) => {
      return data
        .filter((brandollar: IVoucher) => brandollar.isActive === true)
        .sort((a: IVoucher, b: IVoucher) => a.price - b.price)
        .reduce((group, element) => {
          const { id } = element;
          group[id] = element;
          return group;
        }, {} as { [id: number]: IVoucher });
    }),
  );

  const getChartData = (period: string, interval: TimeInterval, range?: DateRange | null) => {
    const { startDate, endDate } = getStartAndEndDate(period, range);
    const navigationItemParams = getBaseAnalyticsRequestParams(
      startDate,
      endDate,
      entityType === MERCHANTS
        ? `voucher_total_sales_${entityId},voucher_total_credits_${entityId},voucher_counts_${entityId}`
        : 'total_cash,total_count,total_credit',
      entityId,
      getTimeZone(),
      entityType as EntityType,
      undefined,
      brandollars ? Object.keys(brandollars).join(',') : undefined,
      'voucher_id',
      entityType === MERCHANTS ? 'merchant_voucher' : undefined,
    );

    if (!navigationItemParams) {
      throw new Error('BrandollarsBreakdown - navigationItemParams is undefined');
    }
    setRequestParams({ ...navigationItemParams, interval });
  };

  useEffect(() => {
    getChartData(period, interval, range);
  }, [entityId, entityType, period, interval, range]);

  const {
    isError,
    isLoading,
    data: aggregateAndPeriodData,
  } = useQuery(
    ['brandollars-breakdown', requestParams],
    () =>
      getAnalyticsData(
        requestParams!,
        entityType === BRANCH_GROUPS ? BRANCH_GROUP_BRAND_DOLLARS_SET : MERCHANT_BRAND_DOLLARS_SET,
      ).then((data) => {
        return transformAggregateAndPeriodBrandollarData(data.data, interval, brandollars);
      }),
    { enabled: requestParams !== null && brandollars != null, staleTime: 60000 },
  );

  return (
    <ComposedChartContainer>
      <div className="relative ">
        <div className="p-6 flex">
          <div className="flex flex-col gap-2">
            <h3 className="text-base text-[color:var(--gray11)] font-medium leading-4">Brandollar Tier</h3>
            <h1 className="text-2xl text-[color:var(--gray12)] font-medium leading-6">Transactions</h1>
          </div>
          <div className="ml-auto flex flex-col gap-2">
            <div className="text-[10px] text-[color:var(--gray9)] text-right leading-4">Visualizing</div>
            <Dropdown
              id={'brandollars-visualizing'}
              handleSelectionChanged={(event) => {
                const value = event.target.value;
                setMetric(value as BrandollarMetrics);
              }}
              options={BRANDOLLARS_METRICS_VALUES}
              selectedValue={metric}
              labelText=""
            />
          </div>
        </div>
        {isLoading && <ChartLoader />}
        {!isLoading && !isError && (
          <ImpressionsAggregateTableBarChart
            loading={isLoading}
            retry={() => queryClient.invalidateQueries({ queryKey: ['brandollars-breakdown'] })}
            tableHeader={BRANDOLLAR_BREAKDOWN_HEADER}
            aggregateAndPeriodData={aggregateAndPeriodData}
            visualizing={entityType === MERCHANTS ? `${metric}_${entityId}` : getBranchGroupMeticName(metric)}
            stacked={true}
            startDate={formatChartSubmissionDate(startDate.toDate())}
            endDate={formatChartSubmissionDate(endDate.toDate())}
            prefixYAxis={metric === 'voucher_counts' ? '' : '$'}
            defaultSort="transactions"
            isAscending={false}
          />
        )}
        {isError && (
          <ChartRetry onErrorRetry={() => queryClient.invalidateQueries({ queryKey: ['brandollars-breakdown'] })} />
        )}
      </div>
    </ComposedChartContainer>
  );
};
