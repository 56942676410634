import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTrigger,
  Slider,
  Text,
} from '@liven-engineering/liven-react-lib';
import moment from 'moment';
import { Clock } from 'phosphor-react';
import { useState } from 'react';

const displayTime = (val: number) => {
  const hours = val / 1;
  const minutes = hours % 1 ? '30' : '00';
  return moment(`${hours / 1}:${minutes}`, 'HH:mm').format('h:mma');
};

type TimeRangePickerProps = {
  open: boolean;
  onClose?: () => void;
  onOk?: () => void;
  onChange?: (dates: Date[]) => void;
  value: Date[] | null;
};
export const TimeRangePicker: React.FC<TimeRangePickerProps> = ({ open, value, onClose, onChange, onOk }) => {
  const [range, setRange] = useState<number[]>(
    value && value.length === 2
      ? [
          value[0].getMinutes() === 0 ? value[0].getHours() : value[0].getHours() + 0.5,
          value[1].getMinutes() === 0 ? value[1].getHours() : value[1].getHours() + 0.5,
        ]
      : [0.0, 24],
  );
  const apply = () => {
    const startTime = new Date();
    startTime.setHours(range[0] / 1);
    startTime.setMinutes(range[0] % 1 ? 30 : 0);
    const endTime = new Date();
    endTime.setHours(range[1] / 1);
    endTime.setMinutes(range[1] % 1 ? 30 : 0);
    onChange?.([startTime, endTime]);
    onOk?.();
  };

  return (
    <Modal modal={true} open={open}>
      <ModalTrigger />
      {open && (
        <ModalContent>
          <ModalHeader>
            <Clock className="text-[color:var(--gray12)]" />{' '}
            <span className="text-[color:var(--gray12)]">Select a start and end time</span>
          </ModalHeader>
          <ModalBody>
            <div className="pt-4">
              <Slider
                aria-label="slider"
                defaultValue={range}
                max={24}
                min={0}
                minStepsBetweenThumbs={1}
                onValueChange={setRange}
                step={0.5}
                variant="primary"
              />
            </div>
            <div className="pt-3 pb-4 flex ">
              <Text>{displayTime(range[0])}</Text>
              <Text className="ml-auto">{displayTime(range[1])}</Text>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={() => onClose?.()} variant="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={() => apply()} variant="primary">
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};
