import { useAppStore } from 'app-store';
import { ChartBar, ChatCircle, FileText } from 'phosphor-react';
import { NavLink } from 'react-router-dom';
import { isBrandollarEnabled } from 'utils/build-flags';
import SVGS from 'views/Logos/logos';

const activeClass =
  'active flex flex-row items-center gap-2.5 px-3 py-2 !mt-0 rounded-lg bg-[color:var(--gray3)] text-[color:var(--gray12)]';
const inactiveClass =
  'flex flex-row items-center gap-2.5 px-3 py-2 !mt-0 rounded-lg text-[color:var(--gray11)] hover:text-[color:var(--gray12)] hover:bg-[color:var(--gray3)]';

const AnalyticMenuItems = [
  {
    key: 'in-store-volume',
    to: 'analytics/instore-volume',
    icon: <ChartBar size={20} weight="fill" color="var(--gray12)" />,
    name: 'In-store payments',
  },
  {
    key: 'analytics-dollars',
    to: 'analytics/dollars',
    icon: <ChartBar size={20} weight="light" />,
    name: 'Brandollars',
  },
];

export const SidebarContainer = () => {
  const selectedEntityData = useAppStore((state) => state.selectedEntityData());

  const mainMenuItems = [
    ...(isBrandollarEnabled() && selectedEntityData?.brandollarsEditAllowed
      ? [
          {
            key: 'brandollars',
            to: 'dollars',
            icon: <SVGS.Foodollars className="foodollar-icon" aria-hidden="true" />,
            name: 'Brandollars',
          },
        ]
      : []),
    {
      key: 'statements',
      to: 'statements/weekly',
      icon: <FileText size={20} weight="regular" />,
      name: 'Statements',
    },
    {
      key: 'feedback',
      to: 'feedback',
      icon: <ChatCircle size={20} weight="regular" />,
      name: 'Feedback',
    },
  ];

  return (
    <nav aria-label="Primary Sidebar" className="flex flex-col">
      <div className="border-b-[1px] border-[color:var(--gray7)] p-2 flex flex-col gap-[1px] pb-6">
        {mainMenuItems.map((nav) => {
          return (
            <NavLink
              key={nav.key}
              to={`./${nav.to}`}
              data-testid={nav.key}
              className={({ isActive }) => (isActive ? activeClass : inactiveClass)}
            >
              {nav.icon}
              <span className="text-sm font-medium leading-4">{nav.name}</span>
            </NavLink>
          );
        })}
      </div>
      <div className="p-2 pt-4 flex flex-col gap-[1px] ">
        <span className="font-medium text-xs pl-4 text-[color:var(--gray9)] leading-4 pb-2">Analytics</span>
        {AnalyticMenuItems.map((nav) => {
          return (
            <NavLink
              key={nav.key}
              to={`./${nav.to}`}
              data-testid={nav.key}
              className={({ isActive }) => (isActive ? activeClass : inactiveClass)}
            >
              {nav.icon}
              <span className="text-sm font-medium leading-4">{nav.name}</span>
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
};
