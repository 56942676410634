import { ThemeProvider } from 'components/theme/theme-provider';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isBrandollarEnabled } from 'utils/build-flags';
import DesktopRedirectionInfo from 'views/DesktopRedirectionInfo/redirect-info';
import Layout from 'views/routes/Layout/layout';
import Login from 'views/routes/authentication/Login/login';
// Routes
import { BrandollarsMainContainer } from 'views/routes/merchant/Brandollars/brandollars-main';
import { Contact } from 'views/routes/merchant/Contact/contact';
import { Feedback } from 'views/routes/merchant/Feedback/feedback';
import { AnalyticsContainer } from 'views/routes/merchant/analytics/analytics-container';
import { InStoreVolumeContainer } from 'views/routes/merchant/analytics/in-store-volume-container';
import { AppContainer } from 'views/routes/merchant/app-container';
import { CommonContainer } from 'views/routes/merchant/common-container';
import { EmptyContainer } from 'views/routes/merchant/empty-container';
import { StatementsContainer } from 'views/routes/merchant/statements/statements-container';

const App = () => {
  return (
    <React.Fragment>
      <DesktopRedirectionInfo />
      <ThemeProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<AppContainer />}>
              <Route path=":entityType/:entityId" element={<EmptyContainer />}>
                <Route index element={<Navigate to={'analytics'} />} />
                {isBrandollarEnabled() && <Route path="dollars" element={<BrandollarsMainContainer />}></Route>}
                <Route path="feedback" element={<Feedback />} />
                <Route path="contact" element={<Contact />} />
                <Route
                  path="statements/weekly"
                  element={
                    <CommonContainer
                      title="Weekly Statements"
                      description="View or download weekly statements for the branch."
                    />
                  }
                >
                  <Route path="" element={<StatementsContainer />}></Route>
                </Route>
                <Route path="analytics">
                  <Route index element={<Navigate to={'instore-volume'} />} />
                  <Route path="dollars" element={<AnalyticsContainer />} />
                  <Route path="instore-volume" element={<InStoreVolumeContainer />} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/login" replace />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
