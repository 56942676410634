export enum ThemeSelection {
  System = 'system',
  Light = 'light',
  Dark = 'dark',
}
export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  userId: number;
}

export interface DropdownOption {
  key: string;
  value: string;
}

// TODO: Keep this interface for now, and remove DropdownOption in future
export interface IDropdownOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface Merchant {
  merchantId: number;
  merchantName: string;
  zone: string;
  branchIds: number[];
  brandollarsEditAllowed: boolean;
}

export interface BranchGroup {
  branchGroupId: number;
  branchGroupName: string;
  zone: string;
  branchIds: number[];
  merchantIds: number[];
  brandollarsEditAllowed: boolean;
}

export type Branch = {
  branchId: string;
  branchName: string;
  location: string;
  active: boolean;
  merchantId: string;
  merchantName: string;
  brandollarsEditAllowed: boolean;
};

export type Filter = {
  key: string;
  value: string;
};

export interface NavItem {
  name: string;
  path: string;
  icon?: any;
  icon_class?: string;
  flag_key: string;
  hasSubMenu?: boolean;
  submenues?: NavItem[];
}

export type PeriodPayload = {
  value?: Number;
  tooltipLabel?: string;
};

export interface IComposedChartData {
  interval: string;
  lineChartOneThisPeriod?: PeriodPayload;
  lineChartTwoThisPeriod?: PeriodPayload;
  barChartOneThisPeriod?: PeriodPayload;
}

export type ChartColors = {
  colors?: {
    lineChartOnePrimaryColor?: string;
    lineChartTwoPrimaryColor?: string;
    barChartOnePrimaryColor?: string;
  };
  gradients?: {
    lineChartOneGradient?: string;
    lineChartTwoGradient?: string;
  };
};

export type NavItemData = {
  label: string;
  thisPeriodValue: string;
  lastPeriodValue?: string;
};

export interface IAnalyticsRequestParams {
  start_date: string;
  end_date: string;
  metric_names: string;
  merchant_id?: string;
  timezone: string;
  branch_id?: string;
  voucher_id?: string;
  dimensions?: string;
  branch_group_id?: string;
  voucher_type?: 'branch_group_voucher' | 'merchant_voucher';
}

export interface INavItemsRequestParams extends IAnalyticsRequestParams {}

export type TimeInterval = 'week' | 'month' | 'year' | 'day' | 'hour';

export type BrandollarMetrics = 'voucher_counts' | 'voucher_total_sales' | 'voucher_total_credits';

export type InStoreMetrics = 'transaction_counts' | 'gross_sales' | 'redemption_amount';

export interface IChartDataRequestParams extends IAnalyticsRequestParams {
  interval: TimeInterval;
}

export interface IAnalyticsResponse {
  metric_name: string;
  metric_value: string;
  metric_timestamp: string;
  merchant_id?: number;
  branch_id?: number;
  voucher_id?: number;
}

export interface INavigationItemTransformedResponse {
  label: string;
  thisPeriodValue: string;
  loading?: boolean;
  shape?: 'bar' | 'line';
  valueType?: 'number' | 'dollar';
  color?: string;
  fullWidth?: boolean;
}

export interface Statement {
  startDate: string;
  endDate: string;
  link: string;
}

export type Entities = {
  merchants?: Record<string, Merchant>;
  branchGroups?: Record<string, BranchGroup>;
  branches?: Record<string, Branch>;
};

export type EntityType = 'merchants' | 'branchgroups';

export interface ISelectItem {
  value: string;
  label: string;
}

export interface SelectedEntity {
  id: string;
  type: EntityType;
}

export type GroupWithName = Merchant | BranchGroup;

export interface UseTime {
  days: number[];
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}
export interface IVoucher {
  id: number;
  credit: number;
  isActive: boolean;
  price: number;
  bonus: number;
  bonusRate: number;
  remaining: number;
  startTime: string;
  expireTime: string;
  isListed: boolean;
  userCap: number;
  useTime?: UseTime;
}

export interface IError {
  response: {
    data: {
      code: number;
      detail: string;
      message: string;
    };
  };
}

export interface ITableHeader {
  label: string;
  key: string;
  class: string;
  prefix: string;
}
