import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { AnalyticsDisclaimer } from 'components/charts/analytics-disclaimer';
import { InStoreVolumeCharts } from 'components/charts/in-store-volume-charts';
import { TabGroup } from 'components/tabGroup/TabGroup';
import { useAnalytics } from 'config/segment';
import { transformInStoreVolumeChartsData, transformInStoreVolumeNavigationItemsData } from 'lib/data-transformers';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { AnalyticsDataRequestParams, fetchDollarsAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { EntityType, IAnalyticsRequestParams, INavItemsRequestParams, TimeInterval } from 'types/types';
import { isAnalytics2Enabled } from 'utils/build-flags';
import { AnalyticsTabs, DEFAULT_INTERVAL, DEFAULT_PERIOD, MERCHANTS } from 'utils/constants';
import { getBranchIdsFromEntity, isValidEntityType } from 'utils/entities';
import { MERCHANT_TRANSACTIONS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import { getStartAndEndDate } from './analytics-utils';
import { FilterContainer } from './filter-container';
import { InStoreBreakdown } from './in-store-breakdown';
import { InStoreHeatmap } from './in-store-heatmap';

export const InStoreVolumeContainer = () => {
  const queryClient = useQueryClient();
  const { entityId, entityType } = useParams();
  const [tab, setTab] = useState('Overview');
  const divElement = useRef<HTMLDivElement>(null);
  const [branchId, setBranchId] = useState<string | undefined>('all');
  const [interval, setTimeInterval] = useState<TimeInterval>(DEFAULT_INTERVAL);
  const [analyticsDataRequestParams, setAnalyticsDataRequestParams] = useState<AnalyticsDataRequestParams>();
  const [period, setPeriod] = useState<string>(DEFAULT_PERIOD);
  const analytics = useAnalytics();
  const { pathname } = useLocation();
  const { branchGroups, branches, merchants } = useAppStore((state) => state.entities);
  const [range, setRange] = useState<null | DateRange>(null);

  if (!entityId) {
    throw new Error('InStoreVolumeContainer - entityId is undefined');
  }

  if (!entityType || !isValidEntityType(entityType)) {
    throw new Error('InStoreVolumeContainer - entityType is undefined');
  }

  const options = {
    root: divElement.current,
    threshold: 0.5,
  };
  const { ref: overviewRef } = useInView({
    ...options,
    onChange: (inView) => {
      if (inView) setTab('Overview');
    },
  });
  const { ref: breakdownRef } = useInView({
    ...options,
    onChange: (inView) => {
      if (inView) setTab('Breakdown');
    },
  });

  const getBaseAnalyticsRequestParams = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    metricNames: string,
    entityId: string,
    timezone: string,
    entityType: EntityType,
    branchId?: string,
  ): IAnalyticsRequestParams => {
    const params: Omit<IAnalyticsRequestParams, 'merchant_id'> = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      metric_names: metricNames,
      timezone: timezone,
    };

    if (entityType === MERCHANTS) {
      if (branchId && branchId !== 'all') {
        return {
          ...params,
          merchant_id: entityId,
          branch_id: branchId,
        };
      }
      if (!merchants) {
        throw new Error('InStoreVolumeContainer - merchants is undefined');
      }
      if (!branches) {
        throw new Error('InStoreVolumeContainer - branches is undefined');
      }
      return {
        ...params,
        merchant_id: entityId,
        branch_id: getBranchIdsFromEntity(merchants[entityId].branchIds, branches),
      };
    } else {
      if (branchId && branchId !== 'all') {
        if (!branches) {
          throw new Error('InStoreVolumeContainer - branches is undefined');
        }

        return {
          ...params,
          branch_id: branchId,
        };
      } else {
        if (!branchGroups) {
          throw new Error('InStoreVolumeContainer - branchGroups is undefined');
        }

        return {
          ...params,
          branch_id: branchGroups[entityId].branchIds.join(','),
        };
      }
    }
  };

  const getNavigationRequestParamsForEntity = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    entityType: EntityType,
    entityId: string,
    branchId?: string,
  ): INavItemsRequestParams => {
    return getBaseAnalyticsRequestParams(
      startDate,
      endDate,
      'transaction_counts,gross_sales,redemption_amount',
      entityId,
      getTimeZone(),
      entityType,
      branchId,
    );
  };

  const getChartData = (
    branchId: string | undefined,
    interval: TimeInterval,
    period: string,
    range?: DateRange | null,
  ) => {
    const { startDate, endDate } = getStartAndEndDate(period, range);
    const navigationItemParams = getNavigationRequestParamsForEntity(
      startDate,
      endDate,
      entityType as EntityType,
      entityId,
      branchId,
    );

    if (!navigationItemParams) {
      throw new Error('InStoreVolumeContainer - navigationItemParams is undefined');
    }

    const analyticsDataRequest: AnalyticsDataRequestParams = {
      metricsSet: MERCHANT_TRANSACTIONS_SET,
      navigationItemParams,
      chartsDataParams: { ...navigationItemParams, interval },
    };
    setAnalyticsDataRequestParams(analyticsDataRequest);
  };

  const {
    isLoading,
    isError,
    data: analyticsData,
  } = useQuery(
    ['analytics-in-store', analyticsDataRequestParams],
    () =>
      fetchDollarsAnalyticsData(analyticsDataRequestParams!).then((data) => {
        return {
          navigationItemResponse: transformInStoreVolumeNavigationItemsData(data[0]),
          chartDataResponse: transformInStoreVolumeChartsData(data[1], interval),
        };
      }),
    {
      enabled: !!analyticsDataRequestParams,
      staleTime: 60000,
    },
  );

  useEffect(() => {
    getChartData(branchId, interval, period, range);
  }, [entityId, branchId]);

  useEffect(() => {
    analytics.track('Viewed Analytics In Store Volume', {
      entity_id: entityId,
      branch_id: branchId,
      entity_type: entityType,
      interval,
      period,
      path: pathname,
      screen: 'In Store Volume',
    });
  }, [analytics, branchId, entityId, entityType, interval, pathname, period, range]);

  return (
    <div
      ref={divElement}
      className="flex w-full overflow-x-hidden h-[calc(100vh-3.5rem)] overflow-y-scroll bg-[color:var(--gray1)]"
    >
      <div className="flex flex-col w-full h-fit relative">
        <div className="pt-8 bg-[color:var(--gray1)]">
          <h1 className="w-full mx-auto max-w-5xl text-3xl font-medium text-[color:var(--gray12)]">
            In-store payments
          </h1>
        </div>
        <div className="border-b w-full border-b-1 border-solid border-[color:var(--gray5)] top-0 sticky z-10 flex bg-[color:var(--gray1)]">
          <div className="mx-auto w-full max-w-5xl flex">
            <TabGroup
              tabs={
                isAnalytics2Enabled() ? [AnalyticsTabs.OVERVIEW, AnalyticsTabs.BREAKDOWN] : [AnalyticsTabs.OVERVIEW]
              }
              active={tab}
              onTabClick={(tab) => {
                setTab(tab);
              }}
              contentContainerRef={divElement}
            />
            <div className="ml-auto">
              <FilterContainer
                className="flex flex-row p-2 justify-end"
                showAnalyticsFilters={true}
                enableAllBranchesOption={true}
                data-testid="filter-container"
                handleFilterChanged={(filter) => {
                  if (filter.period === 'custom' && !filter.range) return;
                  setBranchId(filter.branchId ?? undefined);
                  setPeriod(filter.period ?? DEFAULT_PERIOD);
                  setTimeInterval(filter.interval ?? DEFAULT_INTERVAL);
                  if (filter.range) setRange(filter.range);
                  getChartData(
                    filter.branchId ?? 'all',
                    filter.interval ?? DEFAULT_INTERVAL,
                    filter.period ?? DEFAULT_PERIOD,
                    filter.range,
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="bg-[color:var(--gray2)] pb-10 relative ">
          <div className="flex flex-col mx-auto max-w-5xl pt-6 gap-6" data-testid="analytics-in-store-volume">
            <div ref={overviewRef}>
              <h2 id={AnalyticsTabs.OVERVIEW} className="text-2xl pt-6 font-medium text-[color:var(--gray12)]">
                {AnalyticsTabs.OVERVIEW}
              </h2>
            </div>
            <InStoreVolumeCharts
              isLoading={isLoading}
              navigationItemsData={analyticsData?.navigationItemResponse}
              chartsData={analyticsData?.chartDataResponse}
              hasError={isError}
              onErrorRetry={() => {
                getChartData(branchId, interval, period);
                queryClient.invalidateQueries({ queryKey: ['analytics-in-store'] });
              }}
            />
            {isAnalytics2Enabled() && (
              <>
                <h2 id={AnalyticsTabs.BREAKDOWN} className="text-2xl pt-6 font-medium text-[color:var(--gray12)]">
                  {AnalyticsTabs.BREAKDOWN}
                </h2>
                <div ref={breakdownRef}>
                  <InStoreBreakdown period={period} interval={interval} branchId={branchId} range={range} />
                </div>
                <InStoreHeatmap period={period} branchId={branchId} range={range} />
              </>
            )}

            <AnalyticsDisclaimer />
          </div>
        </div>
      </div>
    </div>
  );
};
