import { useAppStore } from 'app-store';
import { useThemeSelection } from 'hooks/useThemeSelection';
import React from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';

export const ThemeProvider: React.FC<React.ReactNode> = (props: any) => {
  const themeSelection = useAppStore((state) => state.selectedTheme);
  const theme = useThemeSelection(themeSelection);
  const selectedMode = theme.darkMode ? 'dark' : 'light';
  document.documentElement.setAttribute('data-theme', selectedMode ?? 'light');
  return <BaseThemeProvider theme={theme}>{props.children}</BaseThemeProvider>;
};
