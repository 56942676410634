import { useAppStore } from 'app-store';
import { GenericError } from 'components/common/generic-error';
import { RightContentContainer } from 'components/containers/content-container/right-content-container';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';
import { isEntityIdValid, isSelectedEntityValid } from 'utils/entities';

type Props = {
  title: string;
  description: string;
};
export const CommonContainer = ({ title, description }: Props) => {
  const { entityType, entityId } = useParams();
  const { merchants, branchGroups } = useAppStore((state) => state.entities);
  const selectedEntity = useAppStore((state) => state.selectedEntity);
  const navigate = useNavigate();

  useEffect(() => {
    if (!entityType && !entityId) {
      if (selectedEntity && isSelectedEntityValid(selectedEntity)) {
        navigate(`${selectedEntity.type}/${selectedEntity.id}`, { replace: true });
      }
    }
  }, [selectedEntity, entityId, entityType, navigate]);

  if (
    (entityId && entityType && entityType === MERCHANTS && merchants && !isEntityIdValid(merchants, entityId)) ||
    (entityId && entityType && entityType === BRANCH_GROUPS && branchGroups && !isEntityIdValid(branchGroups, entityId))
  ) {
    return <GenericError />;
  }

  return (
    <main className="h-full w-full overflow-auto bg-[color:var(--gray2)]">
      <RightContentContainer title={title} description={description} />
    </main>
  );
};
