import { useAppStore } from 'app-store';
import { useThemeSelection } from 'hooks/useThemeSelection';

const url =
  process.env.REACT_APP_CONTACT_CLICKUP_EMBEDED ||
  'https://forms.clickup.com/2700544/f/2jd80-240822/B4EVAKAG7VT8QDPK93';
const urlDark =
  process.env.REACT_APP_CONTACT_CLICKUP_EMBEDED_DARK ||
  'https://forms.clickup.com/2700544/f/2jd80-249756/WD2FXDF1275YQFS3EY';

export const Contact = () => {
  const themeSelection = useAppStore((state) => state.selectedTheme);
  const theme = useThemeSelection(themeSelection);
  return (
    <>
      <iframe
        data-testid="click-up-contact-form"
        title="Merchant Brandollar Creation Support"
        className="clickup-embed clickup-dynamic-height"
        src={theme.darkMode ? urlDark : url}
        width="100%"
        height="100%"
      ></iframe>
    </>
  );
};
