import { nomnieDark, nomnieLight } from '@liven-engineering/liven-react-lib';
import { useEffect, useState } from 'react';
import { ThemeSelection } from 'types/types';

export const useThemeSelection = (themeSelection: ThemeSelection) => {
  const [sysIsDark, setSysIsDark] = useState(false);
  useEffect(() => {
    if (themeSelection === ThemeSelection.System) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme:dark)');

      setSysIsDark(mediaQuery.matches);

      const onSystemThemeChange = (e: MediaQueryListEvent) => setSysIsDark(e.matches);

      mediaQuery.addEventListener('change', onSystemThemeChange);

      return () => {
        mediaQuery.removeEventListener('change', onSystemThemeChange);
      };
    } else {
      setSysIsDark(false);
    }
  }, [themeSelection]);

  return sysIsDark || themeSelection === ThemeSelection.Dark ? nomnieDark : nomnieLight;
};
