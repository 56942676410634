import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger, Text } from '@liven-engineering/liven-react-lib';
import { useAppStore } from 'app-store';
import { Dropdown } from 'components/lib/dropdown';
import moment from 'moment';
import { Calendar, CaretDown, CaretUp, Check } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { Branch, TimeInterval } from 'types/types';
import { BRANCH_GROUPS, DISABLED_INTERVALS, INTERVAL_VALUES, MERCHANTS } from 'utils/constants';
import { DEFAULT_INTERVAL, DEFAULT_PERIOD, DISABLED_TIME_PERIODS, TIME_PERIOD_VALUES } from 'utils/constants';
import { getBranchesByIds } from 'utils/entities';

const focusLabelById = (id: string) => {
  const label: HTMLLabelElement = document.getElementById(id) as HTMLLabelElement;
  if (label) label.click();
};

const displayCheckIcon = (selected: string, id: string) => {
  return (
    (selected && (selected === id || selected.split(',').includes(id.toString())) && (
      <Check className="text-[color:var(--gray12)]" weight="bold" size={16} />
    )) || <span className="ml-4"></span>
  );
};

const updateBranchSelected = (selected: string, id: string) => {
  if (id === 'all') return 'all';
  let all = selected.split(',');
  if (all.includes(id.toString())) {
    all = all.filter((e) => e !== id.toString());
  } else {
    all = all.filter((e) => e !== 'all');
    all.push(id.toString());
  }
  if (all.length === 0) all.push('all');
  return all.join(',');
};

const getBranchName = (branches: Branch[], id: string) => {
  if (id === 'all') return 'All Branches';
  const all = id.split(',');
  const branchName = branches
    .filter((branch) => all.includes(branch.branchId.toString()))
    .map((branch) => branch.branchName)
    .join(', ');
  return branchName;
};

const getRelevantInterval = (range: DateRange | null) => {
  if (range) {
    const days = moment(range?.[1]).diff(moment(range?.[0]), 'days');
    if (days <= 7) return 'hour';
    else if (days <= 30) return 'day';
    else if (days <= 180) return 'week';
  }
  return 'month';
};

export const disableSmallIntervals = (period: string, range: DateRange | null) => {
  return INTERVAL_VALUES.map((option) => ({
    ...option,
    disabled:
      (DISABLED_TIME_PERIODS.includes(period) ||
        ('custom' === period && moment(range?.[1]).diff(moment(range?.[0]), 'days') > 30)) &&
      DISABLED_INTERVALS.includes(option.value),
  }));
};

type FilterProp = {
  branchId?: string | null;
  interval?: TimeInterval;
  period?: string;
  range?: DateRange | null;
};
type Props = {
  showAnalyticsFilters: boolean;
  enableAllBranchesOption?: boolean;
  handleFilterChanged: (filters: FilterProp) => void;
  disabledBranchFilter?: boolean;
  showBranchFilter?: boolean;
};

export const FilterContainer: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  showAnalyticsFilters,
  className,
  enableAllBranchesOption,
  handleFilterChanged,
  disabledBranchFilter,
  showBranchFilter = true,
}) => {
  const INTERVAL = 'interval';
  const PERIOD = 'period';
  const ALL = 'all';
  const { entityType, entityId } = useParams();
  const [branchId, setBranchId] = useState<string | null>(ALL);
  const [period, setPeriod] = useState<string>(DEFAULT_PERIOD);
  const [interval, setTimeInterval] = useState<TimeInterval>(DEFAULT_INTERVAL);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [range, setRange] = useState<null | DateRange>(null);
  const [openSelector, setOpenSelector] = useState(false);
  const ref = useRef<HTMLElement>();
  const { merchants, branchGroups, branches: branchesData } = useAppStore((state) => state.entities);
  useEffect(() => {
    let branches: Branch[] = [];
    if (entityType && entityId && branchesData) {
      if (entityType === MERCHANTS && merchants) {
        branches = getBranchesByIds(merchants[entityId].branchIds, branchesData);
      } else if (entityType === BRANCH_GROUPS && branchGroups) {
        branches = getBranchesByIds(branchGroups[entityId].branchIds, branchesData);
      }
      setBranchId(ALL);
    }
    setBranches(branches);
  }, [entityType, entityId, branchesData, merchants, branchGroups]);

  const filterChanged = (values: FilterProp) => {
    handleFilterChanged?.({
      branchId,
      interval,
      period,
      range,
      ...values,
    });
  };

  if (entityType !== MERCHANTS && entityType !== BRANCH_GROUPS) {
    throw new Error('FilterContainer - Invalid entity type');
  }
  return (
    <>
      <div className={className}>
        {showBranchFilter && branchId && branches && (
          <DropdownMenu open={openSelector} onOpenChange={setOpenSelector}>
            <DropdownTrigger>
              <button
                className="min-w-[250px] max-w-[250px] inline-flex flex-row items-center gap-1 text-[color:var(--gray12)] bg-[color:var(--gray2)] w-fit border  text-sm rounded-md focus:ring-blue-500 p-[7px] border-r-8 border-[color:var(--gray2)] cursor-pointer"
                style={{ boxShadow: 'var(--shadow-sm)' }}
              >
                <Text className="!text-sm !tracking-normal truncate">{getBranchName(branches, branchId)}</Text>

                {openSelector ? (
                  <CaretUp className="text-[color:var(--gray12)] ml-auto min-w-[16px]" size={16} weight="regular" />
                ) : (
                  <CaretDown className="text-[color:var(--gray12)] ml-auto min-w-[16px]" size={16} weight="regular" />
                )}
              </button>
            </DropdownTrigger>
            <DropdownContent align="start" className="flex flex-col overflow-auto max-h-96 z-20">
              <>
                <DropdownItem
                  className="px-[14px]"
                  key={`branch-all`}
                  icon={displayCheckIcon('all', branchId)}
                  onSelect={(event) => {
                    event.preventDefault();
                    setBranchId('all');
                    filterChanged({ branchId: 'all' });
                    setOpenSelector(false);
                  }}
                >
                  All Branches
                </DropdownItem>
                {branches.map((branch) => (
                  <DropdownItem
                    className="px-[14px]"
                    key={`branch-${branch.branchId}`}
                    icon={displayCheckIcon(branchId, branch.branchId)}
                    onSelect={(event) => {
                      event.preventDefault();
                      setBranchId(updateBranchSelected(branchId, branch.branchId));
                      filterChanged({ branchId: updateBranchSelected(branchId, branch.branchId) });
                      // setOpenSelector(false);
                    }}
                  >
                    {branch.branchName}
                  </DropdownItem>
                ))}
              </>
            </DropdownContent>
          </DropdownMenu>
        )}
        {showAnalyticsFilters && (
          <>
            <div className="flex gap-1 items-center relative" ref={ref as React.RefObject<HTMLDivElement>}>
              <Dropdown
                id={PERIOD}
                handleSelectionChanged={(event) => {
                  const value = event.target.value;
                  if (value !== 'custom') {
                    if (DISABLED_TIME_PERIODS.includes(value)) {
                      setTimeInterval('week' as TimeInterval);
                    }
                    setPeriod(value);
                    filterChanged({ period: value, interval: 'week' as TimeInterval });
                  } else {
                    focusLabelById('range-date-label');
                  }
                }}
                options={TIME_PERIOD_VALUES}
                selectedValue={period}
                labelText=""
              />
              {period && period === 'custom' && (
                <Calendar
                  size={24}
                  className="cursor-pointer text-[color:var(--gray11)] hover:text-[color:var(--gray12)] "
                  onClick={() => focusLabelById('range-date-label')}
                />
              )}
              <label id="range-date-label" data-testid="range-date-label">
                <DateRangePicker
                  format="dd-MM-yyyy"
                  className="invisible absolute"
                  data-testid="range-date"
                  isoWeek
                  ranges={[]}
                  container={ref?.current}
                  placement="leftStart"
                  limitEndYear={1}
                  limitStartYear={3}
                  appearance={'subtle'}
                  showOneCalendar={true}
                  character={' to '}
                  shouldDisableDate={(date) => {
                    return (
                      moment(date).isAfter(new Date()) || moment(date).isBefore(moment(new Date()).subtract(2, 'years'))
                    );
                  }}
                  value={range}
                  onChange={(range) => {
                    setRange(range);
                    setTimeInterval(getRelevantInterval(range));
                    setPeriod('custom');
                    filterChanged({ period: 'custom', range, interval: getRelevantInterval(range) as TimeInterval });
                  }}
                />
              </label>
            </div>
            <Dropdown
              id={INTERVAL}
              selectedValue={interval}
              handleSelectionChanged={(event) => {
                setTimeInterval(event.target.value as TimeInterval);

                filterChanged({ interval: event.target.value as TimeInterval });
              }}
              options={disableSmallIntervals(period, range)}
              labelText=""
            />
          </>
        )}
      </div>
    </>
  );
};
