import { Monitor, Sun, Sunglasses } from '@phosphor-icons/react';
import { useAppStore } from 'app-store';
import styled from 'styled-components';
import { ThemeSelection } from 'types/types';

export const ToggleGroup = styled.div`
  display: flex;
  padding: 0.125rem;
  background: var(--elevation1);
  border-radius: 0.625rem;
`;

const Container = styled.div`
  margin: 1rem auto 0.25rem;
`;

export const ThemeSelector: React.FC = () => {
  const themeSelection = useAppStore((state) => state.selectedTheme);
  const setThemeSelection = useAppStore((state) => state.setSelectedTheme);
  const onSelection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selection: ThemeSelection) => {
    e.preventDefault();
    setThemeSelection(selection);
  };

  return (
    <Container>
      <ToggleGroup>
        <button
          type="button"
          className={`flex items-center gap-1.5 py-2 leading-5 text-sm px-3 rounded-lg border border-solid border-transparent ${
            themeSelection === ThemeSelection.System
              ? 'bg-[color:var(--elevation3)] text-liven-pink shadow-sm border-[color:var(--elevation3)]'
              : 'text-[color:var(--gray11)] hover:text-[color:var(--gray12)]'
          }`}
          onClick={(e) => onSelection(e, ThemeSelection.System)}
        >
          <Monitor size={16} width={24} />
          System
        </button>

        <button
          type="button"
          className={`flex items-center gap-1.5 py-2 leading-5 text-sm px-3 rounded-lg border border-solid border-transparent ${
            themeSelection === ThemeSelection.Light
              ? 'bg-[color:var(--elevation3)] text-liven-pink shadow-sm border-[color:var(--elevation3)]'
              : 'text-[color:var(--gray11)] hover:text-[color:var(--gray12)]'
          }`}
          onClick={(e) => onSelection(e, ThemeSelection.Light)}
        >
          <Sun size={16} width={24} />
          Light
        </button>

        <button
          type="button"
          className={`flex items-center gap-1.5 py-2 leading-5 text-sm px-3 rounded-lg border border-solid border-transparent ${
            themeSelection === ThemeSelection.Dark
              ? 'bg-[color:var(--elevation3)] text-liven-pink shadow-sm border-[color:var(--elevation3)]'
              : 'text-[color:var(--gray11)] hover:text-[color:var(--gray12)]'
          }`}
          onClick={(e) => onSelection(e, ThemeSelection.Dark)}
        >
          <Sunglasses size={16}  width={24}/>
          Dark
        </button>
      </ToggleGroup>
    </Container>
  );
};
