import { NoStatements } from 'components/statements/no-statements';
import { RetryStatements } from 'components/statements/retry-statements';
import { StatementsItem } from 'components/statements/statements-item';
import { Statement } from 'types/types';

type Props = {
  loading: boolean;
  isError: boolean;
  statements?: Statement[];
  selectedStatement?: Statement;
  handleStatementSelect: (statement: Statement) => void;
  onStatementActionButtonClicked: (identifier: string, statement: Statement) => void;
  retryStatements: () => void;
  loadingForDownloading?: string;
  onStatementDownloadClicked: (identifier: string, statement: Statement) => void;
};
export const StatementsDateSelector = ({
  loading,
  isError,
  statements,
  selectedStatement,
  handleStatementSelect,
  onStatementActionButtonClicked,
  retryStatements,
  onStatementDownloadClicked,
  loadingForDownloading,
}: Props) => {
  return (
    <aside className="block flex-shrink-0 order-first border-r border-[color:var(--gray6)] h-auto bg-[color:var(--gray3)]  w-1/4 min-w-72">
      <div className="overflow-y-auto overflow-hidden h-full">
        <h2 className="py-8 px-2 text-[color:var(--gray12)] text-lg font-semibold">Statement Period</h2>
        <div className=" w-full flex flex-col px-2">
          <ul>
            {!loading &&
              statements &&
              statements.map((statement: Statement) => (
                <li
                  className={`py-3.5 px-2 mb-2 cursor-pointer hover:bg-[color:var(--gray5)] rounded-xl ${
                    selectedStatement && selectedStatement.startDate === statement.startDate
                      ? 'bg-[color:var(--gray5)]'
                      : ''
                  }`}
                  key={statement.link}
                  onClick={() => {
                    handleStatementSelect(statement);
                  }}
                >
                  <StatementsItem
                    statement={statement}
                    onStatementActionButtonClicked={onStatementActionButtonClicked}
                    onStatementDownloadClicked={onStatementDownloadClicked}
                    loadingForDownloading={loadingForDownloading}
                    isSelected={selectedStatement && selectedStatement.startDate === statement.startDate}
                  />
                </li>
              ))}
            {!loading && statements?.length === 0 && <NoStatements />}
            {isError && <RetryStatements retryStatements={retryStatements} />}
          </ul>
        </div>
      </div>
    </aside>
  );
};
