import {
  Button,
  Checkbox,
  CheckboxItem,
  DropdownContent,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTrigger,
  ParameterPill,
  ParameterPillContainer,
  Text,
  TextInput,
} from '@liven-engineering/liven-react-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { useAnalytics } from 'config/segment';
import { useFormik } from 'formik';
import { useThemeSelection } from 'hooks/useThemeSelection';
import moment from 'moment';
import { CalendarBlank, CircleNotch, Clock, Info, Play, Stack, Stop } from 'phosphor-react';
import { MouseEventHandler, useRef, useState } from 'react';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { IUseTimeProps, IVoucherProps, createBrandDollar } from 'services/merchant/brandollars/brandollars-service';
import { IError } from 'types/types';
import { MERCHANTS } from 'utils/constants';
import SVGS from 'views/Logos/logos';

import {
  DAYS,
  displayAvailabilityTitle,
  displayDateTime,
  displayRedemtionTitle,
  displaySchedulingTitle,
  displayTime,
} from './brandollar-helper';
import { CreateFormStructure, createForm } from './create-form';
import { TimeRangePicker } from './time-range-picker';

const handleSelect = (state: string[], value: string) => {
  const updatedState = state.includes(value) ? state.filter((el) => el !== value) : state.concat(value);
  return updatedState.sort((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b));
};

const PillButton: React.FC<{ children: React.ReactNode; onClick: MouseEventHandler<HTMLButtonElement> }> = ({
  children,
  onClick,
}) => {
  return (
    <button
      type="button"
      className="flex items-center gap-1 px-2 py-[6px] text-xs font-medium text-[color:var(--gray11)] border border-1 border-solid border-[color:var(--gray7)] rounded-lg leading-4"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
function stringToNumberOrNull(num: string) {
  const intNum = +num;
  return intNum > 0 ? intNum : null;
}

const openTimePicker = () => {
  setTimeout(() => {
    const dateTimePicker: HTMLElement = document.getElementsByClassName(
      'rs-calendar-header-title-time',
    )[0] as HTMLElement;
    if (dateTimePicker) dateTimePicker.click();
    else openTimePicker();
  });
};

const focusLabelById = (id: string) => {
  const label: HTMLLabelElement = document.getElementById(id) as HTMLLabelElement;
  if (label) label.click();
};

export const CreateBrandollars = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showLimits, setShowLimits] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [openLimitTime, setOpenLimitTime] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const selectedEntity = useAppStore((state) => state.selectedEntity);
  const analytics = useAnalytics();
  const now = new Date(new Date().getTime() - 5 * 60000);
  const [error, setError] = useState<string | null>();
  const themeSelection = useAppStore((state) => state.selectedTheme);
  const theme = useThemeSelection(themeSelection);
  const { mutate, isLoading } = useMutation((voucherProps: IVoucherProps) => createBrandDollar(voucherProps), {
    onSuccess: (data, variables) => {
      setOpenModal(false);
      queryClient.resetQueries({ queryKey: ['brandollars'] });
      analytics.track('Brandollar Created', {
        entity_type: variables.entity_type,
        entity_id: variables.entity_id,
        brandollar_id: data.id,
        has_parameters: Boolean(data.useTime && Object.values(data.useTime).some((val) => val)),
        buy_amount: data.price,
        get_amount: data.credit,
        scheduled_start_time: data.startTime,
        scheduled_end_time: data.expireTime,
        use_day: form.values.days,
        use_start_time: data.useTime?.startHour && displayTime(data.useTime.startHour, data.useTime.startMinute),
        use_end_time: data.useTime?.endHour && displayTime(data.useTime.endHour, data.useTime.endMinute),
        remaining: data.remaining,
        has_purchase_cap: Boolean(data.userCap),
      });
    },
    onError: (error: IError) => {
      if (error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        setError('Sorry, something went wrong. Please try again later.');
      }
    },
  });
  const ref = useRef<HTMLElement>();
  const form = useFormik<CreateFormStructure>({
    initialValues: createForm.initialValues,
    validationSchema: createForm.validationSchema,
    onSubmit: () => {
      if (selectedEntity) {
        const useTimeProps: IUseTimeProps = {
          days: form.values.days.map((day) => DAYS.indexOf(day) + 1),
          ...(form.values.startTime && { start_time: moment(form.values.startTime).format('HH:mm') }),
          ...(form.values.endTime && {
            end_time:
              moment(form.values.endTime).format('HH:mm') === '00:00'
                ? '23:59'
                : moment(form.values.endTime).format('HH:mm'),
          }),
        };
        const voucherProps: IVoucherProps = {
          entity_type: selectedEntity?.type === MERCHANTS ? 'merchant' : 'branch_group',
          entity_id: +selectedEntity?.id,
          credit: +form.values.get,
          price: +form.values.buy,
          remaining: stringToNumberOrNull(form.values.units),
          ...(form.values.endDate && { expire_time: moment(form.values.endDate).format('YYYY-MM-DDThh:mmZ') }),
          ...(form.values.startDate &&
            moment(form.values.startDate).isAfter(new Date()) && {
              start_time: moment(form.values.startDate).format('YYYY-MM-DDThh:mmZ'),
            }),
          user_cap: form.values.userCap ? 1 : null,
          use_time: useTimeProps,
        };
        mutate(voucherProps);
      }
    },
  });
  const createBrandollar = () => {
    form.resetForm();
    setOpenModal(true);
    setShowLimits(false);
    setOpenLimitTime(false);
    setError(null);
  };
  return (
    <>
      <Modal modal={true} open={openModal} onOpenChange={setOpenModal}>
        <ModalTrigger>
          <div
            onClick={createBrandollar}
            className="text-[color:var(--gray11)] text-base font-normal p-4 border border-1 rounded-xl border-dashed border-[color:var(--gray8)] cursor-pointer hover:border-solid"
          >
            + Add tier
          </div>
        </ModalTrigger>
        {openModal && (
          <ModalContent
            onPointerDownOutside={(e) => e.preventDefault()}
            className={theme.darkMode ? 'rs-theme-dark' : ''}
          >
            <form onSubmit={form.handleSubmit}>
              <ModalHeader displayCloseButton={true}>
                <SVGS.ThreeLayers />
                <span className="text-[color:var(--gray12)]">Create Brandollar Tier</span>
              </ModalHeader>
              <ModalBody className="!p-0 !max-w-[500px] ">
                <div className="w-full !max-w-[500px]" ref={ref as React.RefObject<HTMLDivElement>}>
                  <div className=" p-6 pt-4 pb-5 border-b border-b-1 border-solid">
                    <div className="flex gap-5">
                      <div className="flex w-1/2">
                        <TextInput
                          label="Buy"
                          prefix="$"
                          type="number"
                          name="buy"
                          fontSize="24px"
                          data-testid="buy-input"
                          error={form.touched.buy ? form.errors.buy : undefined}
                          value={form.values.buy}
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                          autoFocus
                        />
                      </div>
                      <div className="flex w-1/2">
                        <TextInput
                          label="Get"
                          name="get"
                          prefix="$"
                          type="number"
                          fontSize="24px"
                          data-testid="get-input"
                          error={form.touched.get ? form.errors.get : undefined}
                          value={form.values.get}
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-6 flex flex-col gap-5">
                    <div>
                      <h4 className="text-xs font-medium text-[color:var(--gray9)]">Scheduling</h4>
                      <Text className="mb-2 !font-medium !font-sans" data-testid="scheduling-message">
                        {displaySchedulingTitle(form.values.startDate, form.values.endDate)}
                      </Text>
                      {form.errors.endDate && (
                        <div
                          className="max-w-[450px] pt-1 text-xs font-medium text-liven-error11"
                          data-testid="end-date-error"
                        >
                          {form.errors.endDate}
                        </div>
                      )}
                      <div className="flex gap-2">
                        <div className="relative">
                          <label id="start-date-label" data-testid="start-date-label">
                            <DatePicker
                              format="yyyy-MM-dd HH:mm"
                              isoWeek
                              container={ref?.current}
                              data-testid="start-date"
                              shouldDisableDate={(date) => {
                                if (form.values.endDate) {
                                  return moment(date).isBefore(now) || moment(date).isSameOrAfter(form.values.endDate);
                                } else return moment(date).isBefore(now);
                              }}
                              className="invisible absolute"
                              onChange={(date) => {
                                form.setFieldValue('startDate', date);
                              }}
                            />
                          </label>
                          {!form.values.startDate && (
                            <PillButton onClick={() => focusLabelById('start-date-label')}>
                              <Play />
                              Add start time
                            </PillButton>
                          )}
                          {form.values.startDate && (
                            <ParameterPillContainer
                              direction={'column'}
                              onClear={() => {
                                form.setFieldValue('startDate', null);
                              }}
                            >
                              <p>From</p>
                              <ParameterPill
                                value={displayDateTime(form.values.startDate, true)}
                                onClick={() => focusLabelById('start-date-label')}
                              />
                              <ParameterPill
                                value={displayDateTime(form.values.startDate, false)}
                                onClick={() => {
                                  focusLabelById('start-date-label');
                                  openTimePicker();
                                }}
                              />
                            </ParameterPillContainer>
                          )}
                        </div>
                        <div className="relative">
                          <label id="end-date-label" data-testid="end-date-label">
                            <DatePicker
                              format="yyyy-MM-dd HH:mm"
                              container={ref?.current}
                              className="invisible absolute"
                              data-testid="end-date"
                              isoWeek
                              shouldDisableDate={(date) => {
                                if (form.values.startDate)
                                  return (
                                    moment(date).isBefore(now) || moment(date).isSameOrBefore(form.values.startDate)
                                  );
                                else return moment(date).isBefore(now);
                              }}
                              onChange={(date) => {
                                form.setFieldValue('endDate', date);
                              }}
                            />
                          </label>
                          {!form.values.endDate && (
                            <PillButton onClick={() => focusLabelById('end-date-label')}>
                              <Stop />
                              Add end time
                            </PillButton>
                          )}
                          {form.values.endDate && (
                            <ParameterPillContainer
                              direction={'column'}
                              onClear={() => {
                                form.setFieldValue('endDate', null);
                              }}
                            >
                              <p>To</p>
                              <ParameterPill
                                value={displayDateTime(form.values.endDate, true)}
                                onClick={() => focusLabelById('end-date-label')}
                              />
                              <ParameterPill
                                value={displayDateTime(form.values.endDate, false)}
                                onClick={() => {
                                  focusLabelById('end-date-label');
                                  openTimePicker();
                                }}
                              />
                            </ParameterPillContainer>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className="text-xs font-medium text-[color:var(--gray9)]">Redemption</h4>
                      <Text className="mb-2 !font-medium z-10 !font-sans" data-testid="redemption-message">
                        {displayRedemtionTitle(form.values.days, form.values.startTime, form.values.endTime)}
                      </Text>
                      <div className="flex gap-2  z-10">
                        <DropdownMenu>
                          <DropdownTrigger>
                            <ParameterPill
                              value={form.values.days.join(', ')}
                              onClear={() => {
                                form.setFieldValue('days', []);
                              }}
                              placeholder={
                                <>
                                  <CalendarBlank />
                                  Limit days of week
                                </>
                              }
                            ></ParameterPill>
                          </DropdownTrigger>
                          <DropdownContent align="start" container={ref?.current}>
                            {DAYS.map((day) => (
                              <CheckboxItem
                                multiSelect
                                checked={form.values.days.includes(day)}
                                onCheckedChange={() => form.setFieldValue('days', handleSelect(form.values.days, day))}
                                key={day}
                              >
                                {day}
                              </CheckboxItem>
                            ))}
                          </DropdownContent>
                        </DropdownMenu>
                        <div className="relative">
                          {openLimitTime && (
                            <TimeRangePicker
                              open={openLimitTime}
                              onClose={() => setOpenLimitTime(false)}
                              onOk={() => setOpenLimitTime(false)}
                              value={
                                form.values.startTime && form.values.endTime
                                  ? [form.values.startTime, form.values.endTime]
                                  : null
                              }
                              onChange={(dates) => {
                                if (dates) {
                                  form.setFieldValue('startTime', dates[0]);
                                  form.validateForm();
                                  setTimeout(() => {
                                    form.setFieldValue('endTime', dates[1]);
                                    form.validateForm();
                                  });
                                }
                              }}
                            />
                          )}

                          {!form.values.endTime && !form.values.startTime && (
                            <PillButton onClick={() => setOpenLimitTime(!openLimitTime)}>
                              <Clock />
                              Limit time of day
                            </PillButton>
                          )}
                          {form.values.endTime && form.values.startTime && (
                            <ParameterPillContainer
                              direction={'row'}
                              onClear={() => {
                                form.setFieldValue('startTime', null);
                                form.setFieldValue('endTime', null);
                              }}
                            >
                              <ParameterPill
                                value={displayDateTime(form.values.startTime, false)}
                                onClick={() => setOpenLimitTime(true)}
                              />
                              <p>to</p>
                              <ParameterPill
                                value={displayDateTime(form.values.endTime, false)}
                                onClick={() => setOpenLimitTime(true)}
                              />
                            </ParameterPillContainer>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className="text-xs font-medium text-[color:var(--gray9)]">Availability</h4>
                      <Text className="mb-2 !font-medium !font-sans" data-testid="availability-message">
                        {displayAvailabilityTitle(form.values.units, form.values.userCap)}
                      </Text>
                      <div className="flex gap-2">
                        {!showLimits && !form.values.units && (
                          <PillButton onClick={() => setShowLimits(true)}>
                            <Stack />
                            Limit units for sale
                          </PillButton>
                        )}
                        {(showLimits || form.values.units) && (
                          <TextInput
                            type="number"
                            name="units"
                            data-testid="units"
                            fontSize="12px"
                            onClear={() => {
                              form.setFieldValue('units', '');
                              setShowLimits(false);
                            }}
                            min={1}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={form.values.units}
                            onChange={form.handleChange}
                          />
                        )}
                        <Checkbox
                          variant="primary"
                          checked={form.values.userCap}
                          id="one-per-customer"
                          name="userCap"
                          description="One per customer"
                          onCheckedChange={(checked) => form.setFieldValue('userCap', checked)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {error && (
                  <>
                    <div className="px-6 text-liven-error11">{error}</div> <br />
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={() => setOpenConfirmationModal(true)}
                  disabled={isLoading}
                  variant="primary"
                >
                  {isLoading && <CircleNotch className="animate-spin" weight="bold" />} Create Brandollar
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        )}
      </Modal>
      <Modal open={openConfirmationModal} onOpenChange={setOpenConfirmationModal}>
        <ModalTrigger />
        <ModalContent>
          <ModalHeader displayCloseButton>
            <Info />
            Create Brandollar
          </ModalHeader>
          <ModalBody className="!max-w-[450px] ">
            Are you sure you want to create this tier? Brandollar tiers will immediately be live and available to
            purchase through the Liven app.
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button type="button" variant="secondary" onClick={() => setOpenConfirmationModal(false)}>
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                setOpenConfirmationModal(false);
                form.submitForm();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
