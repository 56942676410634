import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import { ComposedChartContainer } from 'components/charts/composed-chart';
import { Dropdown } from 'components/lib/dropdown';
import { transformBrandollarHeatMapData } from 'lib/data-transformers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { getAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { EntityType, IChartDataRequestParams, InStoreMetrics } from 'types/types';
import { HOURS, IN_STORE_DEFAULT_METRIC, IN_STORE_METRICS_VALUES } from 'utils/constants';
import { isValidEntityType } from 'utils/entities';
import { MERCHANT_TRANSACTIONS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import { DAYS } from '../Brandollars/brandollar-helper';
import { getBaseAnalyticsRequestParams, getStartAndEndDate } from './analytics-utils';

export const InStoreHeatmap = ({
  period,
  branchId,
  range,
}: {
  period: string;
  branchId: string | undefined;
  range?: DateRange | null;
}) => {
  const { entityId, entityType } = useParams();
  const [requestParams, setRequestParams] = useState<IChartDataRequestParams | null>(null);
  const [metric, setMetric] = useState<InStoreMetrics>(IN_STORE_DEFAULT_METRIC);

  const queryClient = useQueryClient();

  if (!entityId) {
    throw new Error('InStoreHeatmap - entityId is undefined');
  }

  if (!entityType || !isValidEntityType(entityType)) {
    throw new Error('InStoreHeatmap - entityType is undefined');
  }

  const getChartData = (period: string, metric: string, range?: DateRange | null) => {
    const { startDate, endDate } = getStartAndEndDate(period, range);
    const navigationItemParams = getBaseAnalyticsRequestParams(
      startDate,
      endDate,
      metric,
      entityId,
      getTimeZone(),
      entityType as EntityType,
      branchId,
    );

    if (!navigationItemParams) {
      throw new Error('BrandollarsHeatmap - navigationItemParams is undefined');
    }
    setRequestParams({ ...navigationItemParams, interval: 'hour' });
  };

  useEffect(() => {
    getChartData(period, metric, range);
  }, [entityId, period, metric, branchId, range]);

  const {
    isError,
    isLoading,
    data: analyticsData,
  } = useQuery(
    ['InStoreHeatmap-heatmap', requestParams],
    () =>
      getAnalyticsData(requestParams!, MERCHANT_TRANSACTIONS_SET).then((data) => {
        return transformBrandollarHeatMapData(data.data);
      }),
    { enabled: requestParams !== null, staleTime: 60000 },
  );

  return (
    <ComposedChartContainer>
      <div className="relative min-h-[500px]">
        <div className="p-6 flex">
          <div className="flex flex-col gap-2">
            <h3 className="text-base text-[color:var(--gray11)] font-medium leading-4">Heatmap</h3>
            <h1 className="text-2xl text-[color:var(--gray12)] font-medium leading-6">Sales by hour</h1>
          </div>
          <div className="ml-auto flex flex-col gap-2">
            <div className="text-[10px] text-[color:var(--gray9)] text-right leading-4">Visualizing</div>
            <Dropdown
              id={'brandollars-visualizing'}
              handleSelectionChanged={(event) => {
                const value = event.target.value;
                setMetric(value as InStoreMetrics);
              }}
              options={IN_STORE_METRICS_VALUES}
              selectedValue={metric}
              labelText=""
            />
          </div>
        </div>
        {isLoading && <ChartLoader />}
        {!isLoading && !isError && (
          <table className="border-collapse table-fixed w-full">
            <thead>
              <tr>
                <td className="border border-[color:var(--gray7)] px-4 py-3 text-sm font-medium"> </td>
                {DAYS.map((day) => {
                  return (
                    <td
                      key={day}
                      className="border border-[color:var(--gray7)] px-4 py-3 text-sm font-medium text-[color:var(--gray12)]"
                    >
                      {day}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {analyticsData &&
                HOURS.map((hour, index) => {
                  return (
                    <tr key={index}>
                      <td className=" px-4 py-2 text-sm font-medium text-[color:var(--gray12)]">
                        {`0${hour}`.slice(-2)}
                      </td>
                      {DAYS.map((day, index) => {
                        return (
                          <td
                            key={index}
                            className="px-4 py-2 text-xs font-normal text-[color:var(--gray12)]"
                            style={{
                              backgroundColor: `rgba(255, 60, 110, ${
                                analyticsData.hourlyData[hour][DAYS.indexOf(day)] / analyticsData.max
                              })`,
                            }}
                          >
                            {`${metric !== 'transaction_counts' ? '$' : ''}${
                              analyticsData.hourlyData[hour][DAYS.indexOf(day)]
                            }`}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        {isError && (
          <ChartRetry onErrorRetry={() => queryClient.invalidateQueries({ queryKey: ['InStoreHeatmap-heatmap'] })} />
        )}
      </div>
    </ComposedChartContainer>
  );
};
