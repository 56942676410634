import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import { ArrowDown, ArrowUp, CaretLeft, CaretRight } from 'phosphor-react';
import React, { HTMLAttributes } from 'react';
import { ITableHeader } from 'types/types';
import { CHART_COLORS } from 'utils/constants';

import styles from './tablebarchart.module.css';

export const TableBarChartNavigationContainer: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return <div className={styles.barChartNavigation} {...props} />;
};

interface ITableBarChartNavigationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageDifference: number) => void;
}

export const TableBarChartPagination = ({ currentPage, totalPages, onPageChange }: ITableBarChartNavigationProps) => {
  const caretLeftDisabled = currentPage === 1 || totalPages === 0;
  const caretRightDisabled = currentPage === totalPages || totalPages === 0;

  if (totalPages < 2) return null;
  return (
    <div className={styles.pageNumberContainer}>
      <label>
        Page {currentPage} of {totalPages}
      </label>
      <CaretLeft
        size={24}
        onClick={() => {
          if (!caretLeftDisabled) {
            onPageChange(-1);
          }
        }}
      />
      <CaretRight
        size={24}
        onClick={() => {
          if (!caretRightDisabled) {
            onPageChange(1);
          }
        }}
      />
    </div>
  );
};

interface ITableBarChart {
  data: any[];
  loading?: boolean;
  error?: boolean;
  onErrorRetry?: () => void;
  tableHeader: ITableHeader[];
  sort?: string;
  isAsc?: boolean;
  setSort?: (sort: string) => void;
}

export const TableBarChart: React.FC<ITableBarChart> = ({
  data,
  loading,
  error,
  onErrorRetry,
  tableHeader,
  setSort,
  sort,
  isAsc,
}) => {
  const renderContent = () => {
    if (loading) {
      return <ChartLoader />;
    }
    if (error || data === null) {
      return <ChartRetry onErrorRetry={onErrorRetry} />;
    }

    if (data && data.length > 0)
      return (
        <table className="border-collapse table-auto w-full">
          <thead>
            <tr className="border border-[color:var(--gray5)] ">
              {tableHeader.map((header) => {
                return (
                  <td
                    key={header.key}
                    className={`px-2 py-4  text-[color:var(--gray11)] text-sm font-medium text-center ${header.class}`}
                    onClick={() => {
                      setSort?.(header.key);
                    }}
                  >
                    <div className="flex">
                      <span className="m-auto flex items-center gap-[2px] cursor-pointer">
                        {header.key === sort &&
                          (isAsc ? (
                            <ArrowUp size={14} weight="light" className="-mt-[2px]" />
                          ) : (
                            <ArrowDown size={14} weight="light" className="-mt-[2px]" />
                          ))}
                        <span> {header.label}</span>
                      </span>
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((row, indexRow) => (
              <tr key={indexRow}>
                {tableHeader.map((header, index) => {
                  return (
                    <>
                      {header.key === 'icon' && row[header.key] === 'ICON_PLACEHOLDER' ? (
                        <td
                          key={`${indexRow}-${index}`}
                          className={` py-4  text-[color:var(--gray12)] text-sm font-normal text-center ${header.class}`}
                        >
                          <div
                            style={{ backgroundColor: CHART_COLORS[indexRow % 10] }}
                            className={`w-2.5 h-2.5 pl-2 rounded-full float-right`}
                          />
                        </td>
                      ) : (
                        <td
                          key={`${indexRow}-${index}`}
                          className={` py-4  text-[color:var(--gray12)] text-sm font-normal text-center ${header.class}`}
                        >
                          {header.prefix}
                          {row[header.key]}
                        </td>
                      )}
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      );
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>Looks like you haven't had any transactions yet.</div>
      </div>
    );
  };
  return (
    <div className={styles.dataContainer} style={{ ...((loading || error) && { height: '7rem' }) }}>
      {renderContent()}
    </div>
  );
};
