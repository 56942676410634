import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import { ImpressionsAggregateInsightChart } from 'components/charts/ImpressionsAggregateInsightChart';
import { ComposedChartInsightContainer } from 'components/charts/composed-chart';
import { transformAggregateAndPeriodBrandollarData } from 'lib/data-transformers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { getAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { getBrandDollars } from 'services/merchant/brandollars/brandollars-service';
import { BrandollarMetrics, EntityType, IChartDataRequestParams, IVoucher, TimeInterval } from 'types/types';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';
import { isValidEntityType } from 'utils/entities';
import { BRANCH_GROUP_BRAND_DOLLARS_SET, MERCHANT_BRAND_DOLLARS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import { getBaseAnalyticsRequestParams, getStartAndEndDate } from './analytics-utils';

const getInsightName = (metric: string) => {
  switch (metric) {
    case 'voucher_total_sales':
      return 'Cash paid';
    case 'voucher_total_credits':
      return 'Credit purchased';
    default:
      return 'Packages sold';
  }
};

const aggregatedValue = (metric: string, aggregateData: any) => {
  const result: number = aggregateData.reduce(
    (accum: number, current: any) => accum + current[metric] || current[metric],
    0,
  );
  return result.toLocaleString();
};
export const BrandollarsInsights = ({
  period = '28',
  interval = 'week',
  range,
}: {
  period?: string;
  interval?: TimeInterval;
  range?: DateRange | null;
}) => {
  const { entityId, entityType } = useParams();
  const [requestParams, setRequestParams] = useState<IChartDataRequestParams | null>(null);
  const [metric, setMetric] = useState<BrandollarMetrics>('voucher_total_sales');
  const queryClient = useQueryClient();
  if (!entityId) {
    throw new Error('BrandollarsInsights - entityId is undefined');
  }

  if (!entityType || !isValidEntityType(entityType)) {
    throw new Error('BrandollarsInsights - entityType is undefined');
  }

  const { data: brandollars, isLoading: brandollarLoading } = useQuery(
    ['brandollars-for-insight', entityType, entityId],
    () =>
      getBrandDollars(entityId, entityType).then((data) => {
        return data
          .filter((brandollar: IVoucher) => brandollar.isActive === true)
          .sort((a: IVoucher, b: IVoucher) => a.price - b.price)
          .reduce((group, element) => {
            const { id } = element;
            group[id] = element;
            return group;
          }, {} as { [id: number]: IVoucher });
      }),
  );

  const getChartData = (period: string, interval: TimeInterval, range?: DateRange | null) => {
    const { startDate, endDate } = getStartAndEndDate(period, range);
    const navigationItemParams = getBaseAnalyticsRequestParams(
      startDate,
      endDate,
      `voucher_total_sales_${entityId},voucher_total_credits_${entityId},voucher_counts_${entityId}`,
      entityId,
      getTimeZone(),
      entityType as EntityType,
      undefined,
      brandollars ? Object.keys(brandollars).join(',') : undefined,
      'voucher_id',
      entityType === MERCHANTS ? 'merchant_voucher' : undefined,
    );

    if (!navigationItemParams) {
      throw new Error('BrandollarsInsights - navigationItemParams is undefined');
    }
    setRequestParams({ ...navigationItemParams, interval });
  };

  useEffect(() => {
    if (!brandollarLoading) getChartData(period, interval, range);
  }, [entityId, entityType, period, interval, range, brandollarLoading]);

  const [aggregate, setAggregate] = useState<{ [key: string]: React.ReactNode }>();

  const {
    isError,
    isLoading,
    data: aggregateAndPeriodData,
  } = useQuery(
    ['brandollars-insights', requestParams],
    () =>
      getAnalyticsData(
        requestParams!,
        entityType === BRANCH_GROUPS ? BRANCH_GROUP_BRAND_DOLLARS_SET : MERCHANT_BRAND_DOLLARS_SET,
      ).then((data) => {
        const processedData = transformAggregateAndPeriodBrandollarData(data.data, interval, brandollars);
        const result = processedData.aggregateData.reduce((accum, current: any) => {
          accum['cash_paid'] = accum['cash_paid'] + current['cash_paid'] || current['cash_paid'];
          accum['credit_purchased'] = accum['credit_purchased'] + current['credit_purchased'] || current['cash_paid'];
          accum['package_sold'] = accum['package_sold'] + current['transactions'] || current['transactions'];
          return {
            ...accum,
          };
        }, {});
        setAggregate(result);
        return processedData;
      }),
    { enabled: requestParams !== null && brandollars != null, staleTime: 60000 },
  );

  return (
    <>
      <h2 className="text-2xl font-medium text-[color:var(--gray12)]">Insights</h2>
      <h2 className="text-base leading-4 font-medium text-[color:var(--gray12)]">
        {getInsightName(metric)} <span className="text-[color:var(--gray9)]"> &nbsp;Last 28 days</span>
      </h2>

      <ComposedChartInsightContainer>
        <div className="relative ">
          {isLoading && <ChartLoader />}
          {!isLoading && !isError && (
            <div className="flex">
              <ImpressionsAggregateInsightChart
                loading={isLoading}
                aggregateAndPeriodData={aggregateAndPeriodData}
                visualizing={`${metric}_${entityId}`}
                stacked={true}
                prefixYAxis={metric === 'voucher_counts' ? '' : '$'}
              />
              <div className="max-w-[100px]">
                <div
                  className="flex flex-col mx-2 my-3 gap-1 cursor-pointer"
                  onClick={() => setMetric('voucher_total_sales')}
                >
                  <span className="text-[color:var(--gray11)] text-xs font-medium leading-4">Cash paid</span>
                  <span className="text-[color:var(--gray12)] text-xl font-medium leading-4">
                    ${aggregatedValue('cash_paid', aggregateAndPeriodData.aggregateData)}
                  </span>
                </div>
                <div
                  className="flex flex-col mx-2 my-3 gap-1 cursor-pointer"
                  onClick={() => setMetric('voucher_total_credits')}
                >
                  <span className="text-[color:var(--gray11)] text-xs font-medium leading-4">Credit purchased</span>
                  <span className="text-[color:var(--gray12)] text-xl font-medium leading-4">
                    ${aggregatedValue('credit_purchased', aggregateAndPeriodData.aggregateData)}
                  </span>
                </div>
                <div
                  className="flex flex-col mx-2 my-3 gap-1 cursor-pointer"
                  onClick={() => setMetric('voucher_counts')}
                >
                  <span className="text-[color:var(--gray11)] text-xs font-medium leading-4">Packages sold</span>
                  <span className="text-[color:var(--gray12)] text-xl font-medium leading-4">
                    {aggregatedValue('transactions', aggregateAndPeriodData.aggregateData)}
                  </span>
                </div>
              </div>
            </div>
          )}
          {isError && (
            <ChartRetry onErrorRetry={() => queryClient.invalidateQueries({ queryKey: ['brandollars-insights'] })} />
          )}
        </div>
      </ComposedChartInsightContainer>
    </>
  );
};
