import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <>
      <div className="min-h-full flex flex-col bg-[color:var(--gray3)] h-screen">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
