import { useAppStore } from 'app-store';
import { useThemeSelection } from 'hooks/useThemeSelection';

const url =
  process.env.REACT_APP_FEEDBACK_CLICKUP_EMBEDED ||
  'https://forms.clickup.com/2700544/f/2jd80-238642/9PMW0XU79AQ9DS8RTA';

const urlDark =
  process.env.REACT_APP_FEEDBACK_CLICKUP_EMBEDED_DARK ||
  'https://forms.clickup.com/2700544/f/2jd80-249776/YP0IC71M4GVFZBFLF8';

export const Feedback = () => {
  const themeSelection = useAppStore((state) => state.selectedTheme);
  const theme = useThemeSelection(themeSelection);
  return (
    <>
      <iframe
        data-testid="click-up-form"
        title="Merchant Portal Feedback"
        className="clickup-embed clickup-dynamic-height"
        src={theme.darkMode ? urlDark : url}
        width="100%"
        height="100%"
      ></iframe>
    </>
  );
};
