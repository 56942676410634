import React from 'react';
import { IDropdownOption } from 'types/types';

type Props = {
  handleSelectionChanged: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue?: string;
  options: IDropdownOption[];
  labelText: string;
  disabled?: boolean;
};

export const Dropdown: React.FC<Props & React.HTMLAttributes<HTMLSelectElement>> = ({
  selectedValue,
  handleSelectionChanged,
  options,
  id,
  labelText,
  disabled = false,
}) => {
  return (
    <div className="ml-4">
      {labelText && (
        <label htmlFor={id} className="block mb-2 text-xs font-normal text-[color:var(--gray11)]">
          {labelText}
        </label>
      )}
      <select
        onChange={handleSelectionChanged}
        value={selectedValue}
        className={`bg-[color:var(--gray2)] w-fit border ${
          disabled ? 'text-[color:var(--gray11)]' : 'text-[color:var(--gray12)]'
        } text-sm rounded-md focus:ring-blue-500 block p-[7px] border-r-8 border-[color:var(--gray2)] cursor-pointer`}
        style={{ boxShadow: 'var(--shadow-sm)' }}
        id={id}
        data-testid={id}
        disabled={disabled}
      >
        {options.map(({ value, label, disabled }) => (
          <option key={value} value={value} disabled={disabled} className="leading-3 min-h-0 pb-0">
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
