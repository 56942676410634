import { Disclosure } from '@headlessui/react';
import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger } from '@liven-engineering/liven-react-lib';
import { Dot } from '@phosphor-icons/react';
import { useAppStore } from 'app-store';
import { EntitiesSelector } from 'components/navbar/entities-selector';
import { ThemeSelector } from 'components/theme/theme-selector';
import { SignOut, User, UserGear } from 'phosphor-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout, resetUserInfo } from 'services/authentication/logout';
import { Regions } from 'utils/constants';
import { getCookie } from 'utils/cookies';
import SVGS from 'views/Logos/logos';

import { ChangePassword } from './change-password';

const Navbar = () => {
  const navigate = useNavigate();
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const userInfo = useAppStore((state) => state.userInfo);
  const logoutSession = async () => {
    await logout();
    navigate('/login');
    resetUserInfo();
  };
  const regionCookie = getCookie('region');
  const region = Object.values(Regions).includes(regionCookie as Regions) ? regionCookie : Regions.AU;

  return (
    <Disclosure
      as="nav"
      data-testid="app-nav"
      className="bg-[color:var(--gray1)] border-b-[1px] border-[color:var(--gray7)] w-full fixed "
    >
      <div className="max-w-full px-5 py-4 sm:px-5 lg:px-5">
        <div className="relative flex items-center justify-between h-6">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <div className=" flex items-center justify-center">
                <SVGS.LivenFullLogo className="h-6 w-auto" />
              </div>
              <EntitiesSelector />
            </div>
          </div>
          <div className="float-right">
            <DropdownMenu modal={false}>
              <DropdownTrigger>
                <div
                  data-testid="user-menu"
                  className="border border-1 border-[color:var(--gray8)] float-right p-2.5 rounded-full cursor-pointer"
                >
                  <User weight="bold" size={20} className="text-[color:var(--gray12)]" />
                </div>
              </DropdownTrigger>
              <DropdownContent align="start" className="flex flex-col overflow-auto mr-1 z-20 !p-1">
                <div className="flex gap-3 pt-2 pb-0 px-3">
                  <div className="flex relative justify-center items-center h-10 w-10 rounded-full bg-[color:var(--gray2)]">
                    <User weight="bold" size={20} className="text-[color:var(--gray12)]" />
                    <Dot weight="bold" size={60} className="text-liven-success-10 absolute -bottom-6 -right-6" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="text-sm font-medium leading-5 text-[color:var(--gray12)]">
                      {userInfo?.firstName} {userInfo?.lastName}
                    </div>
                    <div className="font-normal text-sm leading-5 text-[color:var(--gray11)]">{userInfo?.email}</div>
                  </div>
                </div>
                <ThemeSelector />
                <span className='border-t border solid border-[color:var(--gray3)] my-2 mx-0'></span>
                <DropdownItem
                  className="px-[14px] !py-[6px] hover:bg-[color:var(--gray4)]"
                  key={`change-password`}
                  data-testid="change-password"
                  icon={<UserGear size={20} weight="regular" />}
                  onSelect={() => setOpenPasswordModal(true)}
                >
                  Change Password
                </DropdownItem>
                <DropdownItem
                  className="px-[14px] !py-[6px] hover:bg-[color:var(--gray4)]"
                  key={`logout`}
                  data-testid="logout"
                  icon={<SignOut size={20} weight="regular" className="text-liven-error-9" />}
                  onSelect={() => logoutSession()}
                >
                  <span className="text-liven-error-9"> Log out</span>
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
            <div
              data-testid="flag"
              className="border border-1 border-[color:var(--gray8)] float-right p-2.5 rounded-full mr-2"
            >
              <img src={`/${region.toLowerCase()}.png`} width={'20px'} alt={region} title={region} />
            </div>
            <ChangePassword openPasswordModal={openPasswordModal} setOpenPasswordModal={setOpenPasswordModal} />
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default Navbar;
