import { Branch, BranchGroup, Entities, Merchant, SelectedEntity, ThemeSelection, UserInfo } from 'types/types';
import { create } from 'zustand';

interface IAppState {
  userInfo?: UserInfo;
  entities: Entities;
  selectedEntity?: SelectedEntity;
  selectedTheme: ThemeSelection;
  setEntities: (entities: Entities) => void;
  setSelectEntity: (selectedEntity: SelectedEntity) => void;
  setUserInfo: (userInfo?: UserInfo) => void;
  selectedEntityData: () => Merchant | Branch | BranchGroup | undefined;
  resetStore: () => void;
  setSelectedTheme: (selectedTheme: ThemeSelection) => void;
}

export const useAppStore = create<IAppState>((set, get) => ({
  entities: {},
  selectedTheme: localStorage.getItem('ThemeSelection') as  ThemeSelection ?? ThemeSelection.System,
  setSelectedTheme: (selectedTheme: ThemeSelection) => {
    set({ selectedTheme })
    localStorage.setItem('ThemeSelection',selectedTheme)
  },
  setEntities: (entities) => set({ entities }),
  setSelectEntity: (selectedEntity) => set({ selectedEntity }),
  setUserInfo: (userInfo) => set({ userInfo }),
  selectedEntityData: () => {
    const selectedEntity = get().selectedEntity;
    if (!selectedEntity) {
      return undefined;
    }
    // TODO: Clean up different naming of branch groups
    const entityList = get().entities[selectedEntity.type === 'branchgroups' ? 'branchGroups' : 'merchants'];
    return entityList?.[selectedEntity.id];
  },
  resetStore: () => {
    set({ userInfo: undefined, entities: undefined, selectedEntity: undefined });
  },
}));
