import { WarningCircle } from 'phosphor-react';
import React from 'react';

type Props = {
  title?: string;
  description?: string;
};

const DEFAULT_DISCLAIMER =
  'These charts provide estimates only and are subject to change. Estimates shown on this page, or shown on any notification that we send to you, may differ from your actual total transactions. Please refer to your account statement for final numbers.';

export const AnalyticsDisclaimer: React.FC<Props> = ({ title = 'Disclaimer', description = DEFAULT_DISCLAIMER }) => {
  return (
    <div
      className="flex flex-row p-4 mb-5 rounded-xl bg-[color:var(--gray4)] text-[color:var(--gray12)]"
      data-testid="analytics-disclaimer"
    >
      <div>
        <WarningCircle size={18} weight="fill" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-semibold leading-5">{title}</div>
        <p className="leading-4.5 text-xs font-normal">{description}</p>
      </div>
    </div>
  );
};
