import { AggregateAndPeriodData } from 'lib/data-transformers';
import _ from 'lodash';
import { useState } from 'react';
import { ITableHeader } from 'types/types';
import { CHART_COLORS } from 'utils/constants';

import { StackedAreaChart, StackedAreaChartData } from './StackedAreaChart/StackedAreaChart';
import {
  TableBarChart,
  TableBarChartNavigationContainer,
  TableBarChartPagination,
} from './TableBarChart/TableBarChart';

const DEFAULT_PAGE_SIZE = 10;

interface IImpressionsAggregateTableBarChartProps {
  tableHeader: ITableHeader[];
  loading: boolean;
  retry?: () => void;
  aggregateAndPeriodData: AggregateAndPeriodData;
  visualizing: string;
  stacked: boolean;
  startDate: string;
  endDate: string;
  prefixYAxis?: string;
  defaultSort?: string;
  isAscending?: boolean;
}

export const ImpressionsAggregateTableBarChart = ({
  tableHeader,
  loading,
  retry,
  aggregateAndPeriodData,
  visualizing,
  stacked,
  startDate,
  endDate,
  prefixYAxis = '',
  defaultSort,
  isAscending = true,
}: IImpressionsAggregateTableBarChartProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (pageDifference: number) => {
    setCurrentPage((prev) => prev + pageDifference);
  };

  const startIndex = (currentPage - 1) * DEFAULT_PAGE_SIZE;
  const endIndex = startIndex + DEFAULT_PAGE_SIZE;

  const { aggregateData: data, periodData: periodDataMetrics } = aggregateAndPeriodData || {};
  const [sort, setSort] = useState(defaultSort);
  const [isAsc, setIsAsc] = useState(isAscending);
  const periodData = periodDataMetrics[visualizing];
  const aggregateData = _.orderBy(data, [sort], [isAsc ? 'asc' : 'desc']);

  const totalPages = aggregateData?.length ? Math.ceil(aggregateData.length / DEFAULT_PAGE_SIZE) : 0;

  const paginationData = aggregateData?.slice(startIndex, endIndex);

  const rowIds = paginationData?.map((a: any) => a.id);
  const periodPaginatedData = periodData?.map((item: StackedAreaChartData) => {
    return {
      interval: item.interval,
      tooltipHeader: item.tooltipHeader,
      ...Object.keys(item).reduce((acc, key) => {
        if (rowIds?.includes(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {} as Record<string, any>),
    };
  });
  const periodDataColors = paginationData?.reduce((acc: any, item: any, index: number) => {
    acc[item.id] = CHART_COLORS[index];
    return acc;
  }, {} as Record<string, string>);

  return (
    <>
      <div className="border-t border-[#DBDBDB] flex flex-col gap-4">
        {paginationData && paginationData.length > 0 && (
          <StackedAreaChart
            height={270}
            data={periodPaginatedData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            loading={loading}
            error={paginationData === null}
            colors={periodDataColors}
            columns={rowIds ?? []}
            stacked={stacked}
            prefixYAxis={prefixYAxis}
          />
        )}
        <TableBarChart
          data={paginationData ?? []}
          loading={loading}
          error={paginationData === null}
          onErrorRetry={retry}
          tableHeader={tableHeader}
          sort={sort}
          isAsc={isAsc}
          setSort={(key) => {
            setSort(key);
            if (key === sort) setIsAsc(!isAsc);
            else setIsAsc(true);
          }}
        />
      </div>
      <div className="p-6 pb-0 flex border-t border-t-1 border-[color:var(--gray7)]">
        <div className="flex flex-col gap-2">
          <h3 className="text-base text-[color:var(--gray9)] font-normal leading-4">For the period</h3>
          <h1 className="text-xl text-[color:var(--gray12)] font-medium leading-6">
            {startDate} - {endDate}
          </h1>
        </div>
        <div className="flex flex-col gap-2 ml-auto">
          <TableBarChartNavigationContainer>
            <TableBarChartPagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          </TableBarChartNavigationContainer>
        </div>
      </div>
    </>
  );
};
