import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppStore } from 'app-store';
import { ChartLoader } from 'components/animation/loader/chart-loader';
import { ChartRetry } from 'components/animation/loader/chart-retry';
import { ImpressionsAggregateTableBarChart } from 'components/charts/ImpressionsAggregateTableBarChart';
import { ComposedChartContainer } from 'components/charts/composed-chart';
import { Dropdown } from 'components/lib/dropdown';
import { transformAggregateAndPeriodInStoreData } from 'lib/data-transformers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { getAnalyticsData } from 'services/merchant/analytics/analytics-service';
import { Branch, EntityType, IChartDataRequestParams, InStoreMetrics, TimeInterval } from 'types/types';
import {
  BRANCH_GROUPS,
  IN_STORE_BREAKDOWN_HEADER,
  IN_STORE_DEFAULT_METRIC,
  IN_STORE_METRICS_VALUES,
  MERCHANTS,
} from 'utils/constants';
import { getBranchesByIds, isValidEntityType } from 'utils/entities';
import { MERCHANT_TRANSACTIONS_SET } from 'utils/metrics-sets';
import { getTimeZone } from 'utils/utils';

import { formatChartSubmissionDate, getBaseAnalyticsRequestParams, getStartAndEndDate } from './analytics-utils';

export const InStoreBreakdown = ({
  period,
  interval,
  branchId,
  range,
}: {
  period: string;
  interval: TimeInterval;
  branchId: string | undefined;
  range?: DateRange | null;
}) => {
  const { entityId, entityType } = useParams();
  const [requestParams, setRequestParams] = useState<IChartDataRequestParams | null>(null);
  const [metric, setMetric] = useState<InStoreMetrics>(IN_STORE_DEFAULT_METRIC);
  const { startDate, endDate } = getStartAndEndDate(period, range);
  const queryClient = useQueryClient();

  if (!entityId) {
    throw new Error('InStoreBreakdown - entityId is undefined');
  }

  if (!entityType || !isValidEntityType(entityType)) {
    throw new Error('InStoreBreakdown - entityType is undefined');
  }
  const [branches, setBranches] = useState<{ [id: number]: Branch }>();
  const { merchants, branchGroups, branches: branchesData } = useAppStore((state) => state.entities);
  useEffect(() => {
    let branches: Branch[] = [];
    if (entityType && entityId && branchesData) {
      if (entityType === MERCHANTS && merchants) {
        branches = getBranchesByIds(merchants[entityId].branchIds, branchesData);
      } else if (entityType === BRANCH_GROUPS && branchGroups) {
        branches = getBranchesByIds(branchGroups[entityId].branchIds, branchesData);
      }
    }
    const branchTransformed = branches.reduce((group, element: Branch) => {
      const { branchId } = element;
      group[+branchId] = element;
      return group;
    }, {} as { [id: number]: Branch });
    setBranches(branchTransformed);
  }, [entityType, entityId, branchesData, merchants, branchGroups]);

  const getChartData = (period: string, interval: TimeInterval, metric: string, range?: DateRange | null) => {
    const navigationItemParams = getBaseAnalyticsRequestParams(
      startDate,
      endDate,
      'transaction_counts,gross_sales,redemption_amount',
      entityId,
      getTimeZone(),
      entityType as EntityType,
      branchId,
      undefined,
      'branch_id',
    );

    if (!navigationItemParams) {
      throw new Error('InStoreBreakdown - navigationItemParams is undefined');
    }
    setRequestParams({ ...navigationItemParams, interval });
  };

  useEffect(() => {
    getChartData(period, interval, metric, range);
  }, [entityId, period, interval, metric, branchId, range]);

  const {
    isError,
    isLoading,
    data: aggregateAndPeriodData,
  } = useQuery(
    ['instore-breakdown', requestParams],
    () =>
      getAnalyticsData(requestParams!, MERCHANT_TRANSACTIONS_SET).then((data) => {
        return transformAggregateAndPeriodInStoreData(data.data, interval, branches!);
      }),
    { enabled: requestParams !== null && branches != null, staleTime: 60000 },
  );

  return (
    <ComposedChartContainer>
      <div className="relative ">
        <div className="p-6 flex">
          <div className="flex flex-col gap-2">
            <h3 className="text-base text-[color:var(--gray11)] font-medium leading-4">Breakdown by</h3>
            <h1 className="text-2xl text-[color:var(--gray12)] font-medium leading-6">Store Locations</h1>
          </div>
          <div className="ml-auto flex flex-col gap-2">
            <div className="text-[10px] text-[color:var(--gray9)] text-right leading-4">Visualizing</div>
            <Dropdown
              id={'in-store-visualizing'}
              handleSelectionChanged={(event) => {
                const value = event.target.value;
                setMetric(value as InStoreMetrics);
              }}
              options={IN_STORE_METRICS_VALUES}
              selectedValue={metric}
              labelText=""
            />
          </div>
        </div>
        {isLoading && <ChartLoader />}
        {!isLoading && !isError && (
          <ImpressionsAggregateTableBarChart
            loading={isLoading}
            retry={() => queryClient.invalidateQueries({ queryKey: ['brandollars-breakdown'] })}
            tableHeader={IN_STORE_BREAKDOWN_HEADER}
            aggregateAndPeriodData={aggregateAndPeriodData}
            visualizing={metric}
            stacked={true}
            startDate={formatChartSubmissionDate(startDate.toDate())}
            endDate={formatChartSubmissionDate(endDate.toDate())}
            prefixYAxis={metric === 'transaction_counts' ? '' : '$'}
            defaultSort="transaction_counts"
            isAscending={false}
          />
        )}
        {isError && (
          <ChartRetry onErrorRetry={() => queryClient.invalidateQueries({ queryKey: ['brandollars-breakdown'] })} />
        )}
      </div>
    </ComposedChartContainer>
  );
};
