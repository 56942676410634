import { IDropdownOption, TimeInterval } from 'types/types';

export const INTERVAL_VALUES: IDropdownOption[] = [
  {
    value: 'hour',
    label: 'Hourly',
  },
  {
    value: 'day',
    label: 'Daily',
  },
  {
    value: 'week',
    label: 'Weekly',
  },
  {
    value: 'month',
    label: 'Monthly',
  },
];

export const TIME_PERIOD_VALUES: IDropdownOption[] = [
  {
    value: '7',
    label: 'Last 7 days',
  },
  {
    value: '28',
    label: 'Last 4 weeks',
  },
  {
    value: '84',
    label: 'Last 12 weeks',
  },
  {
    value: '365',
    label: 'Last 12 months',
  },
  {
    value: 'custom',
    label: 'Custom Range',
  },
];

export const BRANDOLLARS_METRICS_VALUES: IDropdownOption[] = [
  {
    value: 'voucher_counts',
    label: 'Transactions',
  },
  {
    value: 'voucher_total_sales',
    label: 'Cash Paid',
  },
  {
    value: 'voucher_total_credits',
    label: 'Credits Purchased',
  },
];

export const IN_STORE_METRICS_VALUES: IDropdownOption[] = [
  {
    value: 'transaction_counts',
    label: 'Transactions',
  },
  {
    value: 'gross_sales',
    label: 'Volume',
  },
  {
    value: 'redemption_amount',
    label: 'Credits Redeemed',
  },
];

export const DEFAULT_INTERVAL: TimeInterval = 'week';
export const DEFAULT_PERIOD = '84';
export const BRANDOLLAR_DEFAULT_METRIC = 'voucher_counts';
export const IN_STORE_DEFAULT_METRIC = 'transaction_counts';

export const DISABLED_INTERVALS = ['hour', 'day'];
export const DISABLED_TIME_PERIODS = ['84', '365'];

export const BULLET_POINT_UNICODE = '\u2022';

export const MERCHANTS = 'merchants';

export const BRANCH_GROUPS = 'branchgroups';

export enum Regions {
  AU = 'AU',
  SG = 'SG',
  US = 'US',
}

export enum AnalyticsTabs {
  OVERVIEW = 'Overview',
  BREAKDOWN = 'Breakdown',
}

export const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const BRANDOLLAR_BREAKDOWN_HEADER = [
  {
    label: '',
    key: 'icon',
    class: 'pl-4',
    prefix: '',
  },
  {
    label: 'Buy',
    key: 'buy',
    class: '!px-1',
    prefix: '',
  },
  {
    label: 'Get',
    key: 'get',
    class: '!px-1',
    prefix: '',
  },
  {
    label: 'Limits?',
    key: 'limits',
    class: 'border-r border-[color:var(--gray5)] !px-0',
    prefix: '',
  },
  {
    label: '# Transactions',
    key: 'transactions',
    class: '',
    prefix: '',
  },
  {
    label: 'Cash Paid',
    key: 'cash_paid',
    class: '',
    prefix: '$',
  },
  {
    label: 'Credit Purchased',
    key: 'credit_purchased',
    class: '',
    prefix: '$',
  },
];

export const IN_STORE_BREAKDOWN_HEADER = [
  {
    label: '',
    key: 'icon',
    class: 'pl-4',
    prefix: '',
  },
  {
    label: 'Store',
    key: 'store',
    class: ' pl-3 !text-left border-r border-[color:var(--gray5)]',
    prefix: '',
  },
  {
    label: '# Transactions',
    key: 'transaction_counts',
    class: '',
    prefix: '',
  },
  {
    label: 'Volume',
    key: 'gross_sales',
    class: '',
    prefix: '$',
  },
  {
    label: 'Credit Redeemed',
    key: 'redemption_amount',
    class: '',
    prefix: '$',
  },
];

export const CHART_COLORS = [
  '#0091FF',
  '#3E63DD',
  '#8E4EC6',
  '#E93D82',
  '#D6409F',
  '#FFB224',
  '#6E56CF',
  '#70E1C8',
  '#05A2C2',
  '#68DDFD',
];
