import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger, Text } from '@liven-engineering/liven-react-lib';
import { useAppStore } from 'app-store';
import { useAnalytics } from 'config/segment';
import { CaretDown, CaretUp, Check } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GroupWithName, SelectedEntity } from 'types/types';
import { BRANCH_GROUPS, MERCHANTS } from 'utils/constants';
import { checkDuplicateWithName, isSelectedEntityValid } from 'utils/entities';

const displayEntityName = (groups: Record<string, GroupWithName>, id: string, name: string) => {
  return checkDuplicateWithName(groups, name) ? `${name} - ${groups[id].zone}` : name;
};

const displayCheckIcon = (selectedEntity: SelectedEntity | undefined, type: string, id: string) => {
  return (
    (selectedEntity && selectedEntity.type === type && selectedEntity.id === id && (
      <Check className="text-[color:var(--gray12)]" weight="bold" size={16} />
    )) || <span className="ml-4"></span>
  );
};

export const EntitiesSelector = () => {
  const entities = useAppStore((state) => state.entities);
  const selectedEntity = useAppStore((state) => state.selectedEntity);
  const setSelectEntity = useAppStore((state) => state.setSelectEntity);
  const { entityType, entityId } = useParams();
  useEffect(() => {
    if (entityType && entityId) {
      setSelectEntity({ id: entityId, type: entityType === 'merchants' ? 'merchants' : 'branchgroups' });
    }
  }, [entityType, entityId, setSelectEntity]);

  const { merchants, branchGroups } = entities;
  const analytics = useAnalytics();

  if (!merchants && !branchGroups) {
    throw new Error('No merchants or branch groups found');
  }

  const location = useLocation();
  const navigate = useNavigate();
  const [openSelector, setOpenSelector] = useState(false);

  const handleSelectedEntityChanged = (id: string, type: string) => {
    if (type === MERCHANTS || type === BRANCH_GROUPS) {
      analytics.track('Selected Entity Changed', {
        entity_id: id,
        entity_name:
          type === MERCHANTS
            ? merchants && merchants[id].merchantName
            : branchGroups && branchGroups[id].branchGroupName,
        entity_type: type,
      });

      const pathParts = location.pathname.split('/');
      pathParts[1] = type;
      pathParts[2] = id;
      navigate(pathParts.join('/'));
    } else {
      throw new Error('EntitiesSelector - Invalid entity type');
    }
    setOpenSelector(false);
  };

  return (
    <DropdownMenu open={openSelector} onOpenChange={setOpenSelector}>
      <DropdownTrigger>
        <button className="inline-flex mt-2 ml-6 flex-row items-center gap-1 text-[color:var(--gray12)] outline-none">
          {selectedEntity && isSelectedEntityValid(selectedEntity) && (
            <Text className="!font-semibold">
              {selectedEntity.type === 'merchants' && merchants && (
                <>{displayEntityName(merchants, selectedEntity.id, merchants[selectedEntity.id].merchantName)}</>
              )}
              {selectedEntity.type === 'branchgroups' && branchGroups && (
                <>
                  {displayEntityName(branchGroups, selectedEntity.id, branchGroups[selectedEntity.id].branchGroupName)}
                </>
              )}
            </Text>
          )}

          {openSelector ? (
            <CaretUp className="text-[color:var(--gray12)]" size={16} weight="regular" />
          ) : (
            <CaretDown className="text-[color:var(--gray12)]" size={16} weight="regular" />
          )}
        </button>
      </DropdownTrigger>
      <DropdownContent align="start" className="flex flex-col overflow-auto max-h-96 z-20">
        {branchGroups && Object.keys(branchGroups).length > 0 && (
          <>
            <span className="px-[14px] text-xs leading-4 font-medium text-liven-[color:var(--gray9)]">Groups</span>
            {Object.keys(branchGroups).map((branchGroupId) => (
              <DropdownItem
                className="px-[14px]"
                key={`group-${branchGroupId}`}
                icon={displayCheckIcon(selectedEntity, 'branchgroups', branchGroupId)}
                onSelect={(event) => {
                  event.preventDefault();
                  handleSelectedEntityChanged(branchGroupId, 'branchgroups');
                }}
              >
                {displayEntityName(branchGroups, branchGroupId, branchGroups[branchGroupId].branchGroupName)}
              </DropdownItem>
            ))}
          </>
        )}
        {merchants && Object.keys(merchants).length > 0 && (
          <>
            <span className="text-xs px-[14px] leading-4 font-medium text-[color:var(--gray9)]">Brands</span>
            {Object.keys(merchants).map((merchantId) => (
              <DropdownItem
                className="px-[14px]"
                key={`merchant-${merchantId}`}
                icon={displayCheckIcon(selectedEntity, 'merchants', merchantId)}
                onSelect={(event) => {
                  event.preventDefault();
                  handleSelectedEntityChanged(merchantId, 'merchants');
                }}
              >
                {displayEntityName(merchants, merchantId, merchants[merchantId].merchantName)}
              </DropdownItem>
            ))}
          </>
        )}
      </DropdownContent>
    </DropdownMenu>
  );
};
