import { DropdownMenu, DropdownTrigger } from '@liven-engineering/liven-react-lib';
import { DotsThree, EyeSlash } from 'phosphor-react';
import React from 'react';

export type VoucherProps = {
  buyAmount: number;
  creditAmount: number;
  conditions?: string[];
  firstTimeOnly?: boolean;
  dropdownContent?: React.ReactNode;
  isHidden?: boolean;
};

export const Voucher: React.FC<VoucherProps> = ({
  buyAmount,
  creditAmount,
  conditions,
  firstTimeOnly,
  dropdownContent,
  isHidden,
}) => {
  return (
    <div className="p-2 rounded-2xl border border-solid border-[color:var(--gray7)] bg-[color:var(--gray2)]">
      <div className="p-3 flex flex-col relative bg-[color:var(--gray1)] items-center border border-solid border-[color:var(--gray7)] rounded-[13px] shadow-md gap-2 min-h-[70px] place-content-center">
        <div
          className="flex text-xl gap-3 items-center justify-center leading-5 text-[color:var(--gray12)]"
          data-testid="buy-get-container"
        >
          {dropdownContent && (
            <DropdownMenu modal={false} data-testid="voucher-options">
              <DropdownTrigger>
                <button className="absolute right-6 top-3 text-[color:var(--gray9)]">
                  <DotsThree size={20} weight="bold" />
                </button>
              </DropdownTrigger>
              {dropdownContent}
            </DropdownMenu>
          )}
          {isHidden && (
            <div className="flex items-center gap-1 bg-[color:var(--gray12)] text-[color:var(--gray1)] font-medium px-3 py-[2px] rounded-full absolute -top-3">
              <EyeSlash size={15} />
              <span className="text-xs ">Hidden</span>
            </div>
          )}
          Buy ${buyAmount} <span className="text-4xl">•</span> Get ${creditAmount}
        </div>
        {firstTimeOnly && (
          <div className="text-status-green text-xs " data-testid="first-time-condition">
            First time customers only
          </div>
        )}
      </div>

      {conditions && conditions.length > 0 && (
        <ul className="flex flex-row flex-wrap text-center justify-center mt-2 text-xs" data-testid="conditions-list">
          {conditions.map((condition, i) => (
            <li
              key={i}
              data-testid="condition"
              className={`${
                i > 0 ? "before:content-['•'] before:mx-2 before:text-[color:var(--gray9)]" : ''
              } text-[color:var(--gray12)]`}
            >
              {condition}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
